<template>
  <router-link 
      v-if="title"
      :to="careers ? `/careers/${uid}` : `/news/${uid}`"
      class="news-list-item"
      :class="{ '-careers' : careers }"
    >
    <article class="news-list-item_inner">
      <div class="news-list-item_main">
        <span class="news-list-item_category" v-text="category" />
        <h2 class="news-list-item_title typeH3" v-html="title" />
        <p class="news-list-item_lead" v-if="content" v-text="truncatedContent" />
      </div>
      <span v-if="date" class="news-list-item_date" v-text="formatDate(date)" />
      <span v-if="country || city" class="news-list-item_date">
        {{ city }}<br v-if="city && country">{{ country }}
      </span>
    </article>
  </router-link>
</template>

<script>
export default {
  name: "News",
  props: {
    uid: String,
    title: String,
    category: String,
    content: String,
    date: String,
    city: String,
    country: String,
    careers: Boolean,
  },
  methods: {
    formatDate (date) {
      const prismicDate = this.$prismic.asDate(date);
      return new Intl.DateTimeFormat('en-GB', {
        year: "numeric",
        month: "short",
        day: "numeric"
      }).format(prismicDate);
    },
  },
  computed: {
    truncatedContent () {
      if (this.content.length > 240)
        return this.content.substring(0, 240) + '...';
      else
        return this.content;
    },
  }
};
</script>

<style lang="stylus">
.news-list-item {
  position: relative;
  display: block;
  width: 100%;
  padding: 1em 0;
  border-top: 1px solid rgba($blue, 0.25);

  &::after {
    content: "";
    position: absolute;
    top: -1px; right: 0; left: 0;
    height: 1px;
    background-color: $blue;
    transition: transform 0.75s;
    transform-origin: right;
    transform: scaleX(0);
  }

  &:hover::after {
    transform: scaleX(1);
    transform-origin: left;
  }
}

.news-list-item_inner {
  display: flex;
  align-items: flex-start;
}

.news-list-item_category,
.news-list-item_date,
.news-list-item_lead {
  display: inline-block;
  margin: 0.25em 0;
  color: $blue;
  font-family: "Telegraph";
  font-size: 1em;
  font-weight 400;
}

.news-list-item_category {
  flex: 0 0 auto;
  padding-right: calc(var(--respHorPadding) * 0.5);
  min-width: calc(var(--respHorPadding) * 1.75);
}

.news-list-item_title {
  flex: 1 1 auto;
  max-width: 25ch;
  padding-bottom: 0.75em;
  transition: color 0.25s ease;
  margin-right: 2ch;

  @media (max-width: $breakSm) {
    font-size: 1.25em !important;
  }

  .news-list-item:hover & {
    color: $blue;
  }

  .-careers & {
    max-width: 50ch;
  }
}

.news-list-item_lead {
  max-width: 45ch;
  line-height: 1.3;
  flex: 1 1 auto;

  @media (min-width: $breakMd) {
    margin-left: auto;
    margin-right: auto;
  }
}

.news-list-item_main {
  display: flex;
  flex: 1 1 auto;

  @media (max-width: $breakMd) {
    flex-direction: column;
  }
}

.news-list-item_date {
  text-align: right;
  flex: 0 0 auto;

  @media (min-width: $breakMd) {
    width: 12%;
    flex: 0 0 12%;
  }
}
</style>