<template>
  <div data-scroll-section class="ourTechApproach">
    <AnimateText
      tag="h2"
      :appear="appearHeading"
      data-scroll
      data-scroll-call="onAppearHeading, null"
      class="ourTechApproach_heading typeH2"
      v-text="title"
    />
      <div class="ourTechApproach_content">
        <FadeIn class="ourTechApproach_text" :appear="appearHeading">
          <prismic-rich-text :field="content" />
        </FadeIn>
        <OurTechApproachVideo />
      </div>
  </div>
</template>

<script>
import AnimateText from '@/components/Animate/AnimateText';
import FadeIn from '@/components/Animate/FadeIn';
import OurTechApproachVideo from './OurTechApproachVideo';
export default {
  name: 'OurTechApproach',
  components: {
    AnimateText,
    FadeIn,
    OurTechApproachVideo,
  },
  props: {
    title: String,
    content: Array,
  },
  data() {
    return {
      appearHeading: false,
    };
  },
  inject: ['locomotiveScroll'],
  mounted() {
    const scroll = this.locomotiveScroll();
    setTimeout(() => scroll.on('call', (e) => this.ourApproachHandler(e)), 1000);
  },
  methods: {
    ourApproachHandler (caller) {
      let [ func, param ] = caller;
      if (this[func])
        this[func](param);
    },
    onAppearHeading () {
      this.appearHeading = true;
    },
  },
};
</script>

<style lang="stylus">
.ourTechApproach {
  position: relative;
  padding: 12vh 0;
  z-index: 2;

  @media (min-width: $breakMd) {
    padding: 24vh 0 12vh;
  }

  p {
    display: block;
    margin-bottom: 2em;
    line-height: 1.4;

    > div {
      GradientText();
    }

    @media (min-width: $breakMd) {
      font-size: 1.5em;
    }
  }

  strong {
    font-weight: 400;
  }
}

.ourTechApproach_heading > div {
  GradientText();
}

.ourTechApproach_text,
.ourTechApproach_heading {
  padding-left: var(--respHorPadding);
  padding-right: var(--respHorPadding);
  max-width: 74ch;
}

.ourTechApproach_heading {
  margin-bottom: 3rem;
}

.ourTechApproach_text {
  @media (min-width: $breakMd) {
    margin-left: 12vw;
    padding-left: 0;
  }

  @media (max-width: $breakSm) {
    p {
      font-size: 1.25em !important;
    }
  }
}

.ourTechApproach_text p {
  GradientText();
}
</style>