<template>
  <div v-if="document" class="TMS">
    <div data-scroll-section class="TMS_header">
      <PageHeaderBackground bottom-gradient>
        <video src="/assets/videos/investment-products-bg.mp4" type="video/mp4" class="TMS_background -desktop" playsinline loop muted autoplay />
        <video src="/assets/videos/investment-products-bg-mobile.mp4" type="video/mp4" class="TMS_background -mobile" playsinline loop muted autoplay />
        <div class="TMS_backgroundGradient" />
      </PageHeaderBackground>
      <div class="TMS_headerContentContainer">
        <AnimateText tag="h1" appear class="TMS_headerTitle typeH2" v-text="$prismic.asText(document.title)" />
        <FadeIn appear :delay="0.5">
          <prismic-rich-text :field="document.lead" />
        </FadeIn>
      </div>
      <FadeIn tag="div" appear :delay="0.5" class="TMS_headerLogoContainer">
        <img class="TMS_headerLogo" src="/assets/images/fm-logo.png" alt="" />
        <img class="TMS_headerLogo" src="/assets/images/shortlisted-logo.png" alt="" />
      </FadeIn>
    </div>
    <div data-scroll-section class="TMS_row">
      <div class="TMS_feesGraph" data-scroll>
        <FeesGraph v-if="getTMSData.length > 0" :data="getTMSData" format-dates />
      </div>
    </div>
    <div data-scroll-section class="TMS_row">
      <div class="TMS_perfTable" data-scroll>
        <PerfTable v-if="getTMSPerfTableData.length > 0" :data="getTMSPerfTableData" />
      </div>
    </div>
    <div data-scroll-section class="TMS_termsContainer">
      <h3 class="TMS_shortDisclaimer">Past performance is not indicative of future results.</h3>
      <div class="TMS_terms" data-scroll>
        <TermsDetails v-if="getTMSTerms.length > 0" :data="getTMSTerms" />
      </div>
    </div>
    <div data-scroll-section class="TMS_TMS">
      <InLink textCont="Global Data Network" toLink="/investment/gdn" />
    </div>
  </div>
</template>

<script>
import FeesGraph from "../GDN/FeesGraph";
import PerfTable from "../GDN/PerfTable";
import ThemAlloc from "../GDN/ThemAlloc";
import GeoAlloc from "../GDN/GeoAlloc";
import CurrencyAlloc from "../GDN/CurrencyAlloc";
import MarkCapAlloc from "../GDN/MarkCapAlloc";
import TermsDetails from "../GDN/TermsDetails";
import InLink from "../UI/InLink.vue";
import AnimateText from "../Animate/AnimateText.vue";
import FadeIn from "../Animate/FadeIn.vue";
import PageHeaderBackground from '@/components/UI/PageHeaderBackground';
export default {
  name: "TMS",
  components: {
    FeesGraph,
    PerfTable,
    ThemAlloc,
    GeoAlloc,
    CurrencyAlloc,
    MarkCapAlloc,
    TermsDetails,
    InLink,
    AnimateText,
    FadeIn,
    PageHeaderBackground,
  },
  computed: {
    getTMSData() {
      return this.$store.state.TMSData;
    },
    getTMSTerms() {
      return this.$store.state.TMSTerms;
    },
    getThemAllocData() {
      return this.$store.state.themAllocData;
    },
    getTMSPerfTableData() {
      return this.$store.state.TMSPerfTable;
    }
  },
  inject: ['locomotiveScroll'],
  data () {
    return {
      document: null,
    };
  },
  created () {
    this.getContent();
  },
  methods: {
    async getContent() {
      const document = await this.$prismic.client.getSingle('tms_page');
      this.document = document.data;
      setTimeout(() => {
        const scroll = this.locomotiveScroll();
        scroll.start();
      }, 500);
    },
  },
};
</script>

<style lang="stylus">
.TMS {
  color: white;
  position: relative;
  z-index: 1;

  &_header {
    position: relative;
    width: 100vw;
    min-height: 100vh;
    padding: 20vh var(--respHorPadding) 0;
    margin-bottom: 10vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (min-width: $breakMd) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    p {
      max-width: 45ch;
      font-size: 1.5em;
      line-height: 1.4;
      GradientText();

      @media (max-width: $breakMd) {
        font-size: 1.25em;
      }
    }

    .TMS_headerContentContainer {
      display: flex;
      flex-direction: column;
    }

    .typeH2 {
      margin-right: auto;
    }

    .typeH2 div {
      GradientText();
    }

    .TMS_headerLogoContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @media (min-width: $breakMd) {
        width: 40ch;
        margin: 0 auto 2rem;
      }
    }

    .TMS_headerLogo {
      max-width: 50%;
      flex: 0 0 auto;
      margin: 1.5rem auto;



      @media (min-width: $breakMd) {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  &_feesGraph {
    @media (min-width: $breakMd) {
      width: 60%;
    }
  }

  &_feesGraph, &_perfTable {
    locoAppear();
  }

  &_TMS {
    margin-top: 150px;
  }
}

.TMS_row {
  display: flex;
  flex-direction: column;
  padding: 0 var(--respHorPadding);

  > div {
    padding: var(--respHorPadding) 0;
    margin-bottom: calc(var(--respHorPadding) * 2);
  }

  @media (min-width: $breakMd) {
    flex-direction: row;
    justify-content: space-between;

    > div {
      margin-bottom: 0;
    }
  }
}

.TMS_termsContainer {
  padding: 0 var(--respHorPadding);

  > * {
    padding: var(--respHorPadding) 0;
  }
}

.TMS_shortDisclaimer {
  margin: 0 auto;
  color: $blue;
  text-align: center;
  font-family: 'GTFlexa';
  font-weight: 200;

  @media (max-width: $breakSm) {
    font-size: 1rem; 
  }
}

.TMS_background {
  mix-blend-mode: lighten;

  &.-mobile {
    @media (min-width: $breakSm) {
      display: none;  
    }
  }

  &.-desktop {
    @media (max-width: $breakSm) {
      display: none;  
    }
  }
}

.TMS_backgroundGradient {
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  background-image: linear-gradient(to right, rgba($charcoal, 0.85), rgba($charcoal, 0) 75%);
}

.TMS_headerTitle {
  max-width: 80vw;
  margin-bottom: 0.7em;
}
</style>