<template>
  <div class="contactForm">
    <form @submit.prevent="sendEmail">
      <input 
        type="text" 
        v-model="name"
        name="name"
        placeholder="Your Name"
        class="contactForm_input"
        :disabled="success"
      >
      <input 
        type="email" 
        v-model="email"
        name="email"
        placeholder="Email"
        class="contactForm_input"
        :disabled="success"
        >
      <textarea 
        name="message"
        v-model="message"
        rows="6"
        placeholder="How can we help you?"
        class="contactForm_textarea"
        :disabled="success"
      >
      </textarea>
      <p v-text="error" />
      <button
        type="submit"
        class="contactForm_submit"
        :disabled="success || !name || !email || !message"
      >
        {{success ? 'Message sent' : 'Send'}}
      </button>
    </form>
  </div>
</template>

<script>
import emailjs from 'emailjs-com';
export default {
  name: "ContactForm",
  data() {
    return {
      name: '',
      email: '',
      message: '',
      sent: false,
      error: '',
    }
  },
  computed: {
    success () {
      return this.sent && !this.error;
    },
  },
  methods: {
    sendEmail(e) {
      try {
        emailjs.sendForm(
          'service_71h94s2',
          'template_b8n9pmw',
          e.target,
          'user_99YRXY4PVOxMEoNYvODC2', {
            name: this.name,
            email: this.email,
            message: this.message
          }
        );
      } catch(error) {
          this.error = error;
      }

      this.sent = true;
    },
  },
};
</script>

<style lang="stylus" scoped>
.contactForm {
  padding-top: 3em;
  position: relative;

  @media (max-width: $breakSm) {
    padding-top: 1.5em;
  }
}

.contactForm_input,
.contactForm_textarea {
  display: block;
  width: 100%;
  margin-bottom: 1em;
  padding: 1em;
  border: 1px solid $blue;
  border-radius: 7px;
  background-color: rgba($charcoal, 0.25);
  color: currentColor;
  outline: none;
  font-size: 1rem;
  font-family: "Telegraph";
  font-weight: 200;
  line-height: 1.5;
  transition: border-color 0.25s ease, opacity 0.25s ease;
  appearance: none;

  &::placeholder {
    color: rgba(white,0.75);
  }

  &:focus {
    border-color: $lightBlue;
  }

  &[disabled] {
    opacity: 0.25;
    pointer-events: none;
  }

  &:-webkit-autofill {
    box-shadow: 0 0 0 30px $charcoal inset !important;
    -webkit-text-fill-color: white !important;
  }
}

.contactForm_submit {
  position: relative;
  overflow: hidden;
  width: auto;
  min-width: 12rem;
  padding: 1em;
  border: 1px solid $blue;
  border-radius: 10px;
  background: transparent;
  color: $blue;
  font-size: 1.125rem;
  font-family: "Telegraph";
  line-height: 1;
  cursor: pointer;
  margin-right: 4em;
  transition: color 0.05s 0.1s;
  transform: translate3d(0, 0, 0);

  &::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    top: 0; right: 0; bottom: 0; left: 0;
    background-color: $blue;
    transform: scaleX(0);
    transform-origin: center right;
    transition: transform 0.3s;
  }

  &:hover {
    color: $charcoal;

    &::before {
      transform-origin: center left;
      transform: scaleX(1);
    }
  }

  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }
}
</style>