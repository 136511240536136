<template>
  <div data-scroll-section class="ourTechHeader">
    <PageHeaderBackground bottom-gradient>
      <video src="/assets/videos/technology-bg.mp4" type="video/mp4" class="ourTechHeader_background -desktop" playsinline loop muted autoplay />
      <video src="/assets/videos/technology-bg-mobile.mp4" type="video/mp4" class="ourTechHeader_background -mobile" playsinline loop muted autoplay />
      <div class="ourTechHeader_backgroundGradient" />
    </PageHeaderBackground>
    <div data-scroll class="ourTechHeader_content">
      <h1 class="ourTechHeader_h1 typeH1">
        <div v-for="(variant, i) in textVariants" class="ourTechHeader_h1Inner" :key="i">
          <transition mode="out-in" :duration="{ enter: 0, leave: 1500 }">
            <AnimateTextChars v-show="count === i" tag="span" :appear="count === i" :delay="0">
              We <span class="ourTechheader_gradientWord">{{ variant.word }}</span> technology<span class="dot">.</span>
            </AnimateTextChars>
          </transition>
        </div>
      </h1>
    </div>
    <SideLine />
  </div>
</template>

<script>
import PageHeaderBackground from '@/components/UI/PageHeaderBackground';
import AnimateTextChars from '@/components/Animate/AnimateTextChars';
import SideLine from '@/components/UI/SideLine';
export default {
  name: "OurTechHeader",
  components: {
    PageHeaderBackground,
    AnimateTextChars,
    SideLine,
  },
  data () {
    return {
      count: 0,
      swapTimer: null,
    };
  },
  props: {
    headerBefore: String,
    headerAfter: String,
    textVariants: Array,
  },
  mounted () {
    this.swapTimer = setInterval(this.countLoop, 5000);
  },
  beforeDestroy () {
    clearInterval(this.swapTimer);
  },
  methods: {
    countLoop () {
      this.count++;

      if (this.count === this.textVariants.length)
        this.count = 0;
    }
  },
};
</script>

<style lang="stylus">
.ourTechHeader {
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: var(--respHorPadding);
  padding-bottom: 0;

  .dot {
    color: $blue;
  }
}

.ourTechHeader_background {
  mix-blend-mode: lighten;

  &.-mobile {
    @media (min-width: $breakSm) {
      display: none;  
    }
  }

  &.-desktop {
    @media (max-width: $breakSm) {
      display: none;  
    }
  }
}

.ourTechheader_gradientWord .char {
  GradientText();
}

.ourTechHeader_content {
  position: relative;
  width: 100%;
  margin: auto 0;
}

.ourTechHeader_h1 {
  position: relative;
  width: 100%;
  display: flex;
}

.ourTechHeader_h1Inner {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.ourTechHeader_backgroundGradient {
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  background-image: linear-gradient(to right, rgba($charcoal, 0.85), rgba($charcoal, 0) 75%);
}
</style>