<template>
  <component
    :is="tag"
    ref="text"
    class="animate-text"
  >
  <slot />
  </component>
</template>

<script>
import { gsap } from 'gsap';
import { SplitText } from '@/assets/js/gsap/SplitText';
gsap.registerPlugin(SplitText);

export default {
  name: 'AnimateText',
  props: {
    tag: {
      type: String,
      default: 'span',
    },
    duration: {
      type: Number,
      default: 1.75
    },
    delay: {
      type: Number,
      default: 0.25
    },
    stagger: {
      type: Number,
      default: 0.05
    },
    appear: Boolean,
  },
  data () {
    return {
      text: '',
    };
  },
  computed: {
    readyToAnimate () {
      return this.appear;
    }
  },
  watch: {
    appear (newAppear) {
      if (newAppear)
        gsap.delayedCall(this.delay, this.in);
      else
        this.out();
    }
  },
  mounted () {
    if (this.readyToAnimate)
      gsap.delayedCall(this.delay, this.in);
  },
  methods: {
    in () {
      this.text = new SplitText(this.$refs.text, { type: "lines" });
      const tl = gsap.timeline({ defaults: { stagger: this.stagger } });
      tl
        .set(this.text.lines, { rotationX: -90, scale: 0.85, yPercent: 50, opacity: 0 })
        .set(this.$refs.text, { opacity: 1 })
        .addLabel('run')
        .to(this.text.lines, { opacity: 1, duration: this.duration * 0.35, ease: 'linear' }, 'run')
        .to(this.text.lines, { rotationX: 0, scale: 1, yPercent: 0, duration: this.duration, ease: 'expo.out' }, 'run')
        .call(this.revert);
    },
    out () {
      gsap.to(this.$refs.text, { opacity: 0, duration: 0.5 });
    },
  },
};
</script>

<style lang="stylus">
.animate-text {
  perspective: 800px;
  opacity: 0;

  > div {
    transform-origin: bottom center;
  }
}
</style>
