
import Vue from 'vue';

export const state = Vue.observable({
  mobileNavOpen: false,
  links: [
    {
      title: 'Home',
      path: '/',
    },
    {
      title: 'Our Technology',
      path: '/our-technology',
    },
    {
      title: 'Investment Solutions',
      children: [
        {
          title: "Introduction",
          path: "/investment",
        },
        {
          title: "Taranis Market Sentiment",
          path: "/investment/tms",
        },
        {
          title: "Global Data Network",
          path: "/investment/gdn",
        },
      ],
    },
    {
      title: 'News',
      path: '/news',
    },
    {
      title: 'About Us',
      children: [
        // {
        //   title: "Foundation",
        //   path: "/foundation",
        // },
        {
          title: "Team",
          path: "/team",
        },
        // {
        //   title: "Careers",
        //   path: "/careers",
        // },
        {
          title: "Contact",
          path: "/contact",
        },
      ],
    },
  ],
});

export const mutations = {
  toggleMobileNav () { state.mobileNavOpen = !state.mobileNavOpen },
  closeMobileNav () { state.mobileNavOpen = false },
};
