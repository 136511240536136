<template>
  <div class="threeScene">
    <div class="container" ref="container"></div>
    <div class="gradHide"></div>
  </div>
</template>

<script>
import MainThreeScene from "@/classes/MainThreeScene";
import MouseController from "@/utils/MouseController";
import browser from "browser-detect";

export default {
  name: "ThreeScene",
  props: {
    msg: String
  },
  mounted() {
    if (!browser().mobile) MouseController.start();

    if (!MainThreeScene.inited) MainThreeScene.init();
    MainThreeScene.start(this.$refs.container);
  },
  beforeDestroy() {
    MainThreeScene.stop();
    if (!browser().mobile) MouseController.stop();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
.threeScene {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;

  .gradHide {
    width: 100%;
    height: 50%;
    // border: solid red 2px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(rgba(20, 19, 24, 0), rgba(20, 19, 24, 0.7), rgba(20, 19, 24, 1));
  }
}
</style>
