<template>
  <div class="globalNav top" ref="navRef">
    <div class="globalNav_left">
      <router-link to="/">
        <div class="globalNav_left_logo">
          <img
            class="globalNav_left_logo_icon"
            src="/assets/images/blue-icon.svg"
            alt="Taranis logo"
          />
          <transition name="fadeLogo">
            <img
              v-show="!mobileNavOpen"
              class="globalNav_left_logo_word"
              src="/assets/images/white-blue-wordmark.svg"
              alt="Taranis logo"
            />
          </transition>
        </div>
      </router-link>
    </div>
    <div class="globalNav_right">
      <ul>
        <li>
          <NavLink :hasDL="false" textCont="Our Technology" routerLink="/our-technology" />
        </li>
        <li>
          <NavLink
            textCont="Investment Solutions"
            :dropLinks="dropLinks.invSolutions"
            routerLink="#"
          />
        </li>
        <li>
          <NavLink :hasDL="false" textCont="News" routerLink="/news" />
        </li>
        <li>
          <NavLink textCont="About Us" :dropLinks="dropLinks.aboutPages" routerLink="#" />
        </li>
      </ul>
      <MobileNavToggle />
    </div>
  </div>
</template>

<script>
import NavLink from "./UI/NavLink.vue";
import MobileNavToggle from "./MobileNav/MobileNavToggle.vue";
import { state } from "./MobileNav/MobileNavState";
export default {
  name: "GlobalNav",
  props: {
    scrollingUp: Boolean
  },
  components: {
    NavLink,
    MobileNavToggle
  },
  data() {
    return {
      dropLinks: {
        invSolutions: [
          { text: "Introduction", link: "/investment" },
          { text: "Taranis Market Sentiment", link: "/investment/tms" },
          { text: "Global Data Network", link: "/investment/gdn" }
        ],
        aboutPages: [
          // { text: "Foundation", link: "/foundation" },
          { text: "Team", link: "/team" },
          // { text: "Careers", link: "/careers" },
          { text: "Contact", link: "/contact" },
        ]
      }
    };
  },
  computed: {
    mobileNavOpen() {
      return state.mobileNavOpen;
    }
  },
  mounted() {
    setTimeout(() => {
      this.$refs.navRef.classList.add("on");
    }, 500);
  },
  watch: {
    scrollingUp(oldVal, newVal) {
      if (this.scrollingUp) {
        this.$refs.navRef.classList.add("top");
      } else {
        this.$refs.navRef.classList.remove("top");
      }
    }
  },
  destroyed() {
    this.$refs.navRef.classList.remove("on");
  }
};
</script>

<style lang="stylus" scoped>
.globalNav {
  padding: 40px 0 40px var(--respHorPadding);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  zindex('GlobalNav');
  position: fixed;
  top: 0;
  left: 0;
  font-family: 'GTFlexa';
  font-weight: 200;
  opacity: 0;
  transform: translate3d(0, -20px, 0);
  transition: all 0.5s;

  @media (max-width: $breakMd) {
    padding: var(--respHorPadding);
  }

  &.on {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  &_left {
    &_logo {
      display: flex;
      align-items: center;

      &_icon {
        width: 60px;
        height: auto;
        margin-right: 20px;
      }

      &_word {
        width: 150px;
        height: auto;
        opacity: 0;
        transform: translate3d(0, -10px, 0);
        transition: all 0.3s;
        pointer-events: none;
      }
    }
  }

  &_right {
    opacity: 0;
    transform: translate3d(0, -10px, 0);
    transition: all 0.3s;
    pointer-events: none;

    ul {
      display: flex;
      justify-content: flex-end;

      @media (max-width: $breakMd) {
        display: none;
      }

      li {
        list-style-type: none;
      }
    }
  }

  &.top {
    .globalNav_right {
      pointer-events: all;
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }

    .globalNav_left_logo_word {
      pointer-events: all;
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }

  @media (max-width: $breakMd) {
    background-color: transparent;
    transform: translate3d(0, 0, 0);
    opacity: 1;

    .-scrolled & {
      background-color: $charcoal;
    }

    .globalNav_right,
    .globalNav_left_logo_word {
      pointer-events: all;
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
}

.fadeLogo-enter-active, .fadeLogo-leave-active {
  transition: opacity 0.5s ease;
}

.fadeLogo-enter, .fadeLogo-leave-to {
  opacity: 0 !important;
}
</style>