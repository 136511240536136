<template>
  <div v-if="document" class="investmentSolutions">
    <InvestmentSolutionsHeader :title="$prismic.asText(document.title)" :lead="document.lead" />
    <InvestmentSolutionsCircle />
    <div data-scroll-section class="investmentSolutions_invest">
      <InLink textCont="Our Investment Solutions" toLink="/investment/tms" />
    </div>
  </div>
</template>

<script>
import InvestmentSolutionsHeader from "@/components/InvestmentSolutions/InvestmentSolutionsHeader.vue";
import InvestmentSolutionsCircle from "@/components/InvestmentSolutions/InvestmentSolutionsCircle.vue";
import InLink from "../components/UI/InLink.vue";
export default {
  name: "OurTechnology",
  components: {
    InvestmentSolutionsHeader,
    InvestmentSolutionsCircle,
    InLink,
  },
  inject: ['locomotiveScroll'],
  data () {
    return {
      document: null,
    };
  },
  created () {
    this.getContent();
  },
  methods: {
    async getContent() {
      const document = await this.$prismic.client.getSingle('investment_solutions_page');
      this.document = document.data;
      setTimeout(() => {
        const scroll = this.locomotiveScroll();
        scroll.start();
      }, 500);
    },
  },
};
</script>

<style lang="stylus">
.investmentSolutions {
  font-family: 'Telegraph';
  font-weight: 200;

  .typeH2 div {
    GradientText();
  }
}
</style>