<template>
  <div>
    <transition name="disclaimer" mode="out-in">
      <DisclaimerForbidden v-if="disclaimerGDN.forbidden" key="forbidden" />
      <DisclaimerQuestion v-else-if="!disclaimerGDN.answered" key="question" />
      <DisclaimerText v-else-if="!disclaimerGDN.accepted" key="text" />
      <GDN v-else key="page" />
    </transition>
  </div>
</template>

<script>
import DisclaimerQuestion from "@/components/GDN/Disclaimer/DisclaimerQuestion.vue";
import DisclaimerText from "@/components/GDN/Disclaimer/DisclaimerText.vue";
import DisclaimerForbidden from "@/components/GDN/Disclaimer/DisclaimerForbidden.vue";
import GDN from "@/components/GDN/GDN.vue";

export default {
  name: "GDNPage",
  components: {
    DisclaimerQuestion,
    DisclaimerText,
    DisclaimerForbidden,
    GDN,
  },
  computed: {
    disclaimerGDN () {
      return this.$store.state.disclaimerGDN;
    },
  },
};
</script>

<style scoped>
.disclaimer-enter-active,
.disclaimer-leave-active {
  transition: opacity 0.5s ease;
}

.disclaimer-enter,
.disclaimer-leave-to {
  opacity: 0;
}
</style>
