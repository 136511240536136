<template>
  <div data-scroll data-scroll-call="onTermsDetailsAppear, null" class="termsDetails">
    <AnimateText tag="h2" :appear="inView" class="preData">
      Terms & conditions
    </AnimateText>
    <FadeIn tag="table" :appear="inView">
      <tbody>
        <tr v-for="(info, i) in data" :key="i">
          <td>{{info.left}}</td>
          <td>{{info.right}}</td>
        </tr>
      </tbody>
    </FadeIn>
  </div>
</template>

<script>
import AnimateText from '@/components/Animate/AnimateText';
import FadeIn from '@/components/Animate/FadeIn';
export default {
  name: "TermsDetails",
  props: {
    data: []
  },
  components: {
    AnimateText,
    FadeIn,
  },
  data() {
    return {
      inView: false,
    };
  },
  inject: ['locomotiveScroll'],
  mounted() {
    const scroll = this.locomotiveScroll();
    setTimeout(() => scroll.on('call', (e) => this.termsDetailsHandler(e)), 100);
  },
  methods: {
    termsDetailsHandler(caller) {
      let [ func, param ] = caller;
      if (this[func])
        this[func](param);
    },
    onTermsDetailsAppear() {
      this.inView = true;
    }
  },
};
</script>

<style lang="stylus" scoped>
.termsDetails {
  .preData {
    margin-bottom: var(--respHorPadding);
  }

  table {
    font-family: 'Telegraph';
    font-weight: 200;
    font-size: 1.4rem;
    border-collapse: separate;
    border-spacing: 30px 10px;
    transform: translate3d(-30px, 0, 0);

    @media (max-width: $breakMd) {
      font-size: 1.25em;
    }

    @media (max-width: $breakSm) {
      font-size: 1em;
    }

    tr {
      td:first-child {
        color: $blue;
      }
    }
  }
}
</style>