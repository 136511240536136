<template>
  <div class="home">
    <HomeHeader />
    <div v-if="document" data-scroll-section class="home_main">
      <div class="home_main_text">
        <AnimateText
          data-scroll
          data-scroll-call="onAppearP1, null"
          tag="p"
          :appear="appearP1"
          v-html="$prismic.asHTML(document.paragraph_1).replace(/<\/?p[^>]*>/g, '').replace(/<\/strong[^>]*>/g, '</strong> ')"
        />
        <AnimateText
          data-scroll
          data-scroll-call="onAppearP2, null"
          tag="p" 
          :appear="appearP2"
          v-html="$prismic.asHTML(document.paragraph_2).replace(/<\/?p[^>]*>/g, '').replace(/<\/strong[^>]*>/g, '</strong> ')"
        />
      </div>
        <div class="home_main_text asterisk" style="margin-top: 3em">
        <span>* SO-FIT is a self-regulatory organization approved by the Swiss Federal Financial Markets Supervisory Authority (FINMA) 
        for the supervision of financial intermediaries referred to in article 2 al.3 of the Swiss federal law on the fight
        against money laundering and the financing of terrorism (LBA).</span>
      </div>
    </div>

    <div data-scroll-section class="home_ourTech">
      <div class="home_ourTech_appear" ref="ourTech">
        <InLink textCont="Our Technology" toLink="/our-technology" />
      </div>
    </div>
  </div>
</template>

<script>
import HomeHeader from "@/components/home/HomeHeader.vue";
import InLink from "../components/UI/InLink.vue";
import AnimateText from '@/components/Animate/AnimateText';
export default {
  name: "Home",
  components: {
    HomeHeader,
    InLink,
    AnimateText,
  },
  data() {
    return {
      document: null,
      appearP1: false,
      appearP2: false,
    };
  },
  inject: ['locomotiveScroll'],
  created () {
    this.getContent();
  },
  mounted() {
    const scroll = this.locomotiveScroll();
    setTimeout(() => scroll.on('call', (e) => this.homeHandler(e)), 100);
  },
  methods: {
    async getContent() {
      const document = await this.$prismic.client.getSingle('home_page');
      this.document = document.data;
      setTimeout(() => {
        const scroll = this.locomotiveScroll();
        scroll.start();
      }, 500);
    },
    homeHandler(caller) {
      let [ func, param ] = caller;
      if (this[func])
        this[func](param);
    },
    onAppearP1() {
      this.appearP1 = true;
    },
    onAppearP2() {
      this.appearP2 = true;
    }
  },
};
</script>

<style lang="stylus">
.home {
  &_main {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5vh var(--respHorPadding) 0;

    &_text {
      max-width: 70ch;
      font-family: 'Telegraph';
      font-weight: 200;

      @media (max-width: $breakSm) {
        max-width: 34ch;
        margin-right: auto;
      }

      p {
        
        div {
          GradientText();
        }

        &:last-child {
          margin-top: 30px;
        }
      }

      strong {
        font-weight: 400;
      }
    }
  }

  &_ourTech {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 120px;

    @media (max-width: $breakSm) { margin-top: 100px; }
  }

  .asterisk {
    display: flex;
    max-width: 68ch;
    width: 100%;
    GradientText();

    span {
      display: block;
      max-width: 60ch;
      margin-right: auto;
    }
  }
}
</style>
