<template>
  <div class="TMSDisclaimer">
    <div data-scroll-section class="TMSDisclaimerQuestion2_header">
      <PageHeaderBackground bottom-gradient>
        <video src="/assets/videos/investment-products-bg.mp4" type="video/mp4" class="TMSDisclaimerQuestion2_background -desktop" playsinline loop muted autoplay />
        <video src="/assets/videos/investment-products-bg-mobile.mp4" type="video/mp4" class="TMSDisclaimerQuestion2_background -mobile" playsinline loop muted autoplay />
        <div class="TMSDisclaimerQuestion2_backgroundGradient" />
      </PageHeaderBackground>
    </div>
    <div data-scroll-section class="TMSDisclaimerQuestion2_inner">
      <AnimateText tag="h1" appear class="typeH2 TMSDisclaimerQuestion2_heading">
        <span class="typeGradient">Taranis Market Sentiment</span>
      </AnimateText>
      <p class="TMSDisclaimerQuestion2_label">Are you a "Professional / Qualified" or "Private / Retail investor"?</p>
      <div class="TMSDisclaimerQuestion2_buttonContainer">
        <button class="TMSDisclaimerQuestion2_button" @click="accept">Professional / Qualified</button>
        <button class="TMSDisclaimerQuestion2_button" @click="decline">Private / Retail investor</button>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeaderBackground from '@/components/UI/PageHeaderBackground';
import AnimateText from '@/components/Animate/AnimateText.vue';
import FadeIn from '@/components/Animate/FadeIn.vue';
export default {
  name: 'TMSDisclaimerQuestion2',
  components: {
    PageHeaderBackground,
    AnimateText,
    FadeIn
  },
  data () {
    return {
      scroll: null,
    };
  },
  inject: ['locomotiveScroll'],
  mounted() {
    this.scroll = this.locomotiveScroll();
  },
  methods: {
    accept () {
      this.$store.commit('setDisclaimerTMS', {
        answered1: true,
        answered2: true,
        accepted: false,
        forbidden: false,
      });
      setTimeout(() => {
        this.scroll.scrollTo("top", { duration: 0, disableLerp: true });
        this.scroll.update();
      }, 500);
    },
    decline () {
      this.$store.commit('setDisclaimerTMS', {
        answered1: true,
        answered2: true,
        accepted: false,
        forbidden: true,
      });
      setTimeout(() => {
        this.scroll.scrollTo("top", { duration: 0, disableLerp: true });
        this.scroll.update();
      }, 500);
    }
  }
}
</script>

<style lang="stylus" scoped>
.TMSDisclaimerQuestion2_header {
  position: absolute; width: 100%; height: 100vh;
}
.TMSDisclaimerQuestion2_inner {
  position: relative;
  width: 100vw;
  min-height: 90vh;
  padding: 20vh var(--respHorPadding) 10vh;
  margin-bottom: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.TMSDisclaimerQuestion2_heading {
  display: inline-block;
  margin-right: auto;
  margin-bottom: 0.75em;
}

.TMSDisclaimerQuestion2_text,
.TMSDisclaimerQuestion2_accept {
  margin-bottom: 3em;
  font-size: 1.2em;
  font-family: "Telegraph";
  font-weight 200;
  color: $lightBlue !important;

  @media (max-width: $breakMd) { font-size: 1em; }
  
  p {
    font: inherit;
    color: $lightBlue !important;
    margin-bottom: 1em;
    line-height: 1.4;
  }

  strong {
    color: $lightBlue !important;
    font-weight: 400;
  }
}

.TMSDisclaimerQuestion2_label {
  GradientText();
  display: block;
  margin-bottom: 1.25em;
  font-size: 1.5em;
  line-height: 1.4;

  @media (max-width: $breakMd) {
    font-size: 1.25em;
  }
}


.TMSDisclaimerQuestion2_background {
  mix-blend-mode: lighten;

  &.-mobile {
    @media (min-width: $breakSm) {
      display: none;  
    }
  }

  &.-desktop {
    @media (max-width: $breakSm) {
      display: none;  
    }
  }
}

.TMSDisclaimerQuestion2_backgroundGradient {
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  background-image: linear-gradient(to right, rgba($charcoal, 0.85), rgba($charcoal, 0) 75%);
}

.TMSDisclaimerQuestion2_buttonContainer {
  display: flex;
  flex-wrap: wrap;
}

.TMSDisclaimerQuestion2_button {
  position: relative;
  display: inline-block;
  overflow: hidden;
  width: auto;
  min-width: 14rem;
  padding: 1em;
  margin-right: 1em;
  margin-bottom: 0.5em;
  border: 1px solid $blue;
  border-radius: 10px;
  background: transparent;
  color: $blue;
  font-size: 1.125rem;
  font-family: "Telegraph";
  line-height: 1;
  cursor: pointer;
  transition: color 0.05s 0.1s;
  transform: translate3d(0, 0, 0);
  text-align: center;

  @media (max-width: $breakSm) {
    min-width: 0;
    flex: 0 0 auto;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    top: 0; right: 0; bottom: 0; left: 0;
    background-color: $blue;
    transform: scaleX(0);
    transform-origin: center right;
    transition: transform 0.3s;
  }

  &:hover {
    color: $charcoal;

    &::before {
      transform-origin: center left;
      transform: scaleX(1);
    }
  }
}
</style>