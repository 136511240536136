<template>
  <div data-scroll-section class="newsHeader">
    <PageHeaderBackground bottom-gradient>
      <video src="/assets/videos/contact-bg.mp4" type="video/mp4" class="newsHeader_background -desktop" playsinline loop muted autoplay />
      <video src="/assets/videos/contact-bg-mobile.mp4" type="video/mp4" class="newsHeader_background -mobile" playsinline loop muted autoplay />
    </PageHeaderBackground>
    <div class="newsHeader_content">
      <AnimateText tag="h1" appear class="newsHeader_contentHeading typeH2" v-text="title" />
    </div>
  </div>
</template>

<script>
import PageHeaderBackground from '@/components/UI/PageHeaderBackground';
import AnimateText from '@/components/Animate/AnimateText';
import FadeIn from '@/components/Animate/FadeIn';
export default {
  name: "NewsHeader",
  components: {
    PageHeaderBackground,
    AnimateText,
    FadeIn,
  },
  props: {
    title: String,
  }
};
</script>

<style lang="stylus">
.newsHeader {
  position: relative;
  width: 100%;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25vh var(--respHorPadding) 0;

  @media (max-width: $breakMd) {
    min-height: 0;
    padding: 20vh var(--respHorPadding) 2rem;
  }
}

.newsHeader .pageHeaderBackground {
  height: 100vh;
}

.newsHeader_background {
  mix-blend-mode: lighten;

  &.-mobile {
    @media (min-width: $breakSm) {
      display: none;  
    }
  }

  &.-desktop {
    @media (max-width: $breakSm) {
      display: none;  
    }
  }
}

.newsHeader_content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
}

.newsHeader_contentHeading {
  flex: 0 0 auto;
  width: 45%;
  margin-bottom: 3rem;

  @media (max-width: $breakMd) {
    width: 67%;
    margin-bottom: 1rem;
  }
}

.newsHeader_contentText {
  width: 60ch;
  padding-top: 1em;

  p {
    display: block;
    margin-bottom: 2em;
    font-size: 1.5em;
    line-height: 1.4;

    @media (max-width: $breakMd) {
      font-size: 1.25em;
    }

    a {
      display: inline-block;
      margin-top: 2em;
      color: $blue;
    }
  }

  img {
    max-width: 45ch;
  }
}
</style>