<template>
    <div data-scroll data-scroll-call="onCurrAllocAppear, null" class="currencyAlloc">
        <AnimateText tag="h2" :appear="inView" class="preData">
            Currency Allocation
        </AnimateText>
        <FadeIn :appear="inView" class="currencyAlloc_canvasContainer">
            <DoughnutGraph v-if="getThemAllocData.length > 0" :data="getThemAllocData" />
        </FadeIn>
    </div>
</template>

<script>
import DoughnutGraph from "./DoughnutGraph.vue";
import AnimateText from '@/components/Animate/AnimateText';
import FadeIn from '@/components/Animate/FadeIn';
export default {
    name: "CurrencyAlloc",
    components: {
        DoughnutGraph,
        AnimateText,
        FadeIn,
    },
    data() {
        return {
        inView: false,
        };
    },
    inject: ['locomotiveScroll'],
    mounted() {
        const scroll = this.locomotiveScroll();
        setTimeout(() => scroll.on('call', (e) => this.CurrAllocHandler(e)), 100);
    },
    methods: {
        CurrAllocHandler(caller) {
            let [ func, param ] = caller;
            if (this[func])
                this[func](param);
        },
        onCurrAllocAppear() {
            this.inView = true;
        }
    },
    computed: {
        getThemAllocData() {
            return this.$store.state.currencyAllocData;
        },
    },
};
</script>

<style lang="stylus" scoped>
.currencyAlloc {
    display: flex;
    flex-direction: column;
    align-items: center;

    &_canvasContainer {
        width: 100%;
    }
}
</style>