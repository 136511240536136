<template>
  <div class="TMSdisclaimer">
    <div data-scroll-section class="TMSdisclaimerForbidden_header">
      <PageHeaderBackground bottom-gradient>
        <video src="/assets/videos/investment-products-bg.mp4" type="video/mp4" class="TMSdisclaimerForbidden_background -desktop" playsinline loop muted autoplay />
        <video src="/assets/videos/investment-products-bg-mobile.mp4" type="video/mp4" class="TMSdisclaimerForbidden_background -mobile" playsinline loop muted autoplay />
        <div class="TMSdisclaimerForbidden_backgroundGradient" />
      </PageHeaderBackground>
    </div>
    <div data-scroll-section class="TMSdisclaimerForbidden_inner">
      <AnimateText tag="h1" appear class="typeH2 TMSdisclaimerForbidden_heading">
        <span class="typeGradient">Taranis Market Sentiment</span>
      </AnimateText>
      <p class="TMSdisclaimerForbidden_label">
        Our Funds are not available in your Country of Residence and/or your category of investor. Please kindly contact us for further information.
      </p>
      <div class="disclaimerQuestion_buttonContainer">
        <router-link to="/contact" class="TMSdisclaimerForbidden_button">Contact us</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeaderBackground from '@/components/UI/PageHeaderBackground';
import AnimateText from '@/components/Animate/AnimateText.vue';
import FadeIn from '@/components/Animate/FadeIn.vue';
export default {
  name: 'TMSDisclaimerForbidden',
  components: {
    PageHeaderBackground,
    AnimateText,
    FadeIn
  },
}
</script>

<style lang="stylus" scoped>
.TMSdisclaimerForbidden_header {
  position: absolute; width: 100%; height: 100vh;
}
.TMSdisclaimerForbidden_inner {
  position: relative;
  width: 100vw;
  min-height: 90vh;
  padding: 20vh var(--respHorPadding) 10vh;
  margin-bottom: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.TMSdisclaimerForbidden_heading {
  display: inline-block;
  margin-right: auto;
  margin-bottom: 0.75em;
}

.TMSdisclaimerForbidden_text,
.TMSdisclaimerForbidden_accept {
  margin-bottom: 3em;
  font-size: 1.2em;
  font-family: "Telegraph";
  font-weight 200;
  color: $lightBlue !important;

  @media (max-width: $breakMd) { font-size: 1em; }
  
  p {
    font: inherit;
    color: $lightBlue !important;
    margin-bottom: 1em;
    line-height: 1.4;
  }

  strong {
    color: $lightBlue !important;
    font-weight: 400;
  }
}

.TMSdisclaimerForbidden_label {
  GradientText();
  display: block;
  margin-bottom: 1.25em;
  font-size: 1.5em;
  line-height: 1.4;
  max-width: 60ch;

  @media (max-width: $breakMd) {
    font-size: 1.25em;
  }
}


.TMSdisclaimerForbidden_background {
  mix-blend-mode: lighten;

  &.-mobile {
    @media (min-width: $breakSm) {
      display: none;  
    }
  }

  &.-desktop {
    @media (max-width: $breakSm) {
      display: none;  
    }
  }
}

.TMSdisclaimerForbidden_backgroundGradient {
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  background-image: linear-gradient(to right, rgba($charcoal, 0.85), rgba($charcoal, 0) 75%);
}

.TMSdisclaimerForbidden_buttonContainer {
  display: flex;
  flex-wrap: wrap;
}
.TMSdisclaimerForbidden_buttonContainer {
  display: flex;
}

.TMSdisclaimerForbidden_button {
  position: relative;
  display: inline-block;
  overflow: hidden;
  width: auto;
  min-width: 14rem;
  padding: 1em;
  margin-bottom: 0.5em;
  border: 1px solid $blue;
  border-radius: 10px;
  background: transparent;
  color: $blue;
  font-size: 1.125rem;
  font-family: "Telegraph";
  line-height: 1;
  cursor: pointer;
  margin-right: 1em;
  transition: color 0.05s 0.1s;
  transform: translate3d(0, 0, 0);
  text-align: center;

  @media (max-width: $breakSm) {
    min-width: 0;
    width: 48%;
    flex: 0 0 48%;
    margin: 0;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    top: 0; right: 0; bottom: 0; left: 0;
    background-color: $blue;
    transform: scaleX(0);
    transform-origin: center right;
    transition: transform 0.3s;
  }

  &:hover {
    color: $charcoal;

    &::before {
      transform-origin: center left;
      transform: scaleX(1);
    }
  }
}
</style>