<template>
  <div id="app" :class="{ '-scrolled' : scrolled }">
    <GlobalNav :scrollingUp="scrollUp" />
    <MobileNav />
    <div data-scroll-container class="page" id="main">
      <div class="page_inner">
        <transition name="fade" mode="out-in">
          <router-view v-if="appLoaded" />
        </transition>
      </div>
      <MainFooter class="mainFooter" />
    </div>
  </div>
</template>

<script>
import LocomotiveScroll from 'locomotive-scroll';
import GlobalNav from "./components/GlobalNav.vue";
import MobileNav from "./components/MobileNav/MobileNav.vue";
import MainFooter from "@/components/MainFooter.vue";
import { EventBus } from '@/eventBus';
export default {
  components: {
    GlobalNav,
    MobileNav,
    MainFooter,
  },
  data() {
    return {
      scroll,
      appLoaded: false,
      scrollUp: true,
      scrolled: false,
    };
  },
  created() {
    this.$store.dispatch("loadGDNData");
    this.$store.dispatch("loadTMSData");
    this.$store.dispatch("loadThemAllocData");
    this.$store.dispatch("loadCurrencyAllocData");
    this.$store.dispatch("loadMarkCapData");
    this.$store.dispatch("loadGDNPerformanceTable");
    this.$store.dispatch("loadTMSPerformanceTable");
    this.$store.dispatch("loadGeographicalAllocation");
    this.$store.dispatch("loadGDNTerms");
    this.$store.dispatch("loadTMSTerms");
  },
  mounted() {
    let prismicPreviewScript = document.createElement('script');
    prismicPreviewScript.setAttribute('async', '');
    prismicPreviewScript.setAttribute('defer', '');
    prismicPreviewScript.setAttribute('src', 'https://static.cdn.prismic.io/prismic.js?new=true&repo=taranis');
    document.head.appendChild(prismicPreviewScript);

    this.scroll = new LocomotiveScroll({
      el: document.querySelector('[data-scroll-container]'),
      smooth: true,
      offset: ['15%', '15%'],
      smartphone: {
        // smooth: true,
      },
    });

    let lastScrollY = 0;
    this.scroll.on('scroll', (e) => {
      EventBus.$emit('scrollPos', e.scroll.y);
      this.scrollUp = e.scroll.y <= lastScrollY || e.scroll.y < 2 ? true : false;
      lastScrollY = e.scroll.y;
      this.scrolled = e.scroll.y > 50;
    });

    this.appLoaded = true;
  },
  updated () {
    this.updateScroll();
  },
  methods: {
    updateScroll () {
      if (this.scroll)
        this.$nextTick(() => this.scroll.update());
    },
    getLocomotiveScroll () {
      return this.scroll;
    }
  },
  watch: {
    '$route' () {
      // Wait for page transition to finish
      setTimeout(() => { 
        this.scroll.scrollTo("top", { duration: 0, disableLerp: true });
        this.updateScroll();
      }, 500);
    }
  },
  beforeDestroy () {
    this.scroll.destroy();
  },
  provide () {
    return {
      locomotiveScroll: this.getLocomotiveScroll
    };
  },
};
</script>

<style lang="stylus">
@font-face {
  font-family: 'GTFlexa';
  src: url('/assets/fonts/GT-Flexa-Extended-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'GTFlexa';
  src: url('/assets/fonts/GT-Flexa-Extended-Thin.ttf') format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'Telegraph';
  src: url('/assets/fonts/Telegraf-Regular-400.otf') format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'Telegraph';
  src: url('/assets/fonts/Telegraf-UltraBold-800.otf') format('opentype');
  font-weight: 800;
}

@font-face {
  font-family: 'Telegraph';
  src: url('/assets/fonts/Telegraf-UltraLight-200.otf') format('opentype');
  font-weight: 200;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: white;
  list-style-type: none;
}

a {
  text-decoration: none;
}

body {
  background: #141318;
  width: 100vw;
  overflow-x: hidden;
}

.page {
  position: relative;
  width: 100%;
  overflow-x: hidden;
  zindex('Page');
}

.page_inner {
  min-height: 100vh;
}

/* Page transitions */
.fade-enter-active, .fade-leave-active, .mainFooter {
  transition: opacity 0.5s ease;
}

.fade-enter, .fade-leave-to, .fade-enter ~ .mainFooter, .fade-leave-to ~ .mainFooter {
  opacity: 0;
}

/* Root CSS variables */
:root {
  --respHorPadding: $horPadding;

  @media (max-width: $breakMd) {
    --respHorPadding: $horPaddingMd;
  }

  @media (max-width: $breakSm) {
    --respHorPadding: $horPaddingSm;
  }
}
</style>
