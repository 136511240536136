<template>
  <div data-scroll-section class="newsPostHeader">
    <div class="newsPostHeader_image-container" data-scroll data-scroll-speed="-1">
      <ResponsiveImage v-if="image.url" :image="image" sizes="" class="newsPostHeader_image" />
    </div>
    <div class="newsPostHeader_content" data-scroll data-scroll-speed="2">
      <span v-if="category || date" class="newsPostHeader_kicker">
        <span v-if="category" v-text="category" />
        <span v-if="category" class="newsPostHeader_kicker-spacer"> | </span>
        <span v-text="formatDate(date)" />
      </span>
      <span v-if="careers" class="newsPostHeader_kicker"><span>Careers</span></span>
      <AnimateText tag="h1" appear class="newsPostHeader_contentHeading typeH2" v-html="title" />
      <span v-if="author" class="newsPostHeader_author">By {{ author }}</span>
      <span v-if="country || city" class="newsPostHeader_author">
        {{ city }}<br v-if="city && country">{{ country }}
      </span>
    </div>
  </div>
</template>

<script>
import PageHeaderBackground from '@/components/UI/PageHeaderBackground';
import AnimateText from '@/components/Animate/AnimateText';
import FadeIn from '@/components/Animate/FadeIn';
import ResponsiveImage from '@/components/ResponsiveImage';
export default {
  name: "NewsPostHeader",
  components: {
    PageHeaderBackground,
    AnimateText,
    FadeIn,
    ResponsiveImage,
  },
  props: {
    title: String,
    category: String,
    date: String,
    author: String,
    image: Object,
    city: String,
    country: String,
    careers: Boolean,
  },
  methods: {
    formatDate (date) {
      if (!date) return '';
      const prismicDate = this.$prismic.asDate(date);
      return new Intl.DateTimeFormat('en-GB', {
        year: "numeric",
        month: "short",
        day: "numeric"
      }).format(prismicDate);
    }
  },
};
</script>

<style lang="stylus">
.newsPostHeader {
  position: relative;
  width: 100%;
  min-height: 85vh;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  padding: 22vh 0 15vh var(--respHorPadding);

  @media (max-width: $breakMd) {
    flex-direction: column;
    min-height: 0;
    padding: 12vh var(--respHorPadding) 0;
  }
}

.newsPostHeader_content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2ch 0;

  @media (max-width: $breakMd) {
    padding: 0;
  }
}

.newsPostHeader_contentHeading {
  position: relative;
  z-index: 1;
  display: block;

  @media (min-width: $breakMd) {
    width: 130%;
  }
}

.newsPostHeader_kicker span,
.newsPostHeader_author {
  display: inline-block;
  margin: 1.75em 0;
  color: $blue;
  font-family: "Telegraph";
  font-size: 1em;
  font-weight 400;
}

.newsPostHeader_kicker-spacer {
  display: inline-block;
  margin: 1.75em 0.75em !important;
}

.newsPostHeader_image-container {
  flex: 0 0 100%;
  width: 100%;
  clip-path: polygon(12% 0, 100% 0, 100% 100%, 12% 100%, 0 88%, 0 12%);

  @media (min-width: $breakMd) {
    flex: 0 0 60%;
    width: 60%;
  }
}

.newsPostHeader_image {
  width: 100%;
  height: auto;
}
</style>