<template>
  <div class="sharing-links">
    <!-- <a :href="facebookLink" target="_blank" rel="noopener noreferrer" class="sharing-links_item">
      <img src="/assets/images/social/facebook.png" alt="">
    </a> -->
    <a :href="mailtoLink" class="sharing-links_item">
      <img src="/assets/images/social/mail.png" alt="">
    </a>
    <!-- <a :href="twitterLink" target="_blank" rel="noopener noreferrer" class="sharing-links_item">
      <img src="/assets/images/social/twitter.png" alt="">
    </a> -->
    <a :href="linkedinLink" target="_blank" rel="noopener noreferrer" class="sharing-links_item">
      <img src="/assets/images/social/linkedin.png" alt="" class="linkedin-icon" >
    </a>
  </div>
</template>

<script>
export default {
  name: "SharingLinks",
  props: {
    link: String,
    title: String,
  },
  computed: {
    // facebookLink () {
    //   return `https://www.facebook.com/sharer/sharer.php?u=${this.link}`;
    // },
    mailtoLink () {
      return `mailto: ?body=${this.title} ${this.link}&subject=${this.title}`;
    },
    // twitterLink () {
    //   return `https://twitter.com/intent/tweet?text=${this.title}&url=${this.link}`;
    // },
    linkedinLink () {
      return `http://www.linkedin.com/shareArticle?mini=true&url=${this.link}&title=${this.title}&source=https://taranis.net`;
    }
  }
};
</script>

<style lang="stylus">
.sharing-links {
  display: flex;
  align-items: center;
}

.sharing-links_item {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height 1.5rem;
  margin-right: 0.5rem;
  border-radius: 50%;
  background-color: $blue;
  transition: opacity 0.25s ease;

  &:hover {
    opacity: 0.75;
  }

  img {
    width: 60%;
  }

  .linkedin-icon {
    width: 50%;
  }
}
</style>