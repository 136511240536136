import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import GDN from '../views/GDNPage.vue'
import Contact from '../views/Contact.vue'
import Team from '../views/Team.vue'
import Foundation from '../views/Foundation.vue'
import OurTechnology from '../views/OurTechnology.vue'
import TMS from '../views/TMSPage.vue'
import InvestmentSolutions from '../views/InvestmentSolutions.vue'
import News from '../views/News.vue'
// import Careers from '../views/Careers.vue'
import NewsPost from '../views/NewsPost.vue'
// import CareersPost from '../views/CareersPost.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/our-technology',
        name: 'OurTechnology',
        component: OurTechnology
    },
    {
        path: '/investment',
        name: 'InvestmentSolutions',
        component: InvestmentSolutions
    },
    {
        path: '/investment/gdn',
        name: 'gdn',
        component: GDN
    },
    {
        path: '/investment/tms',
        name: 'tms',
        component: TMS
    },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact
    },
    // {
    //     path: '/foundation',
    //     name: 'Foundation',
    //     component: Foundation
    // },
    {
        path: '/team',
        name: 'Team',
        component: Team
    },
    {
        path: '/news',
        name: 'News',
        component: News
    },
    {
        path: '/news/:uid',
        name: 'News post',
        component: NewsPost
    },
    // {
    //     path: '/careers',
    //     name: 'Careers',
    //     component: Careers
    // },
    // {
    //     path: '/careers/:uid',
    //     name: 'Careers post',
    //     component: CareersPost
    // },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
