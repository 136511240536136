<template>
    <div data-scroll data-scroll-call="onThemAllocAppear, null" class="themAlloc">
        <AnimateText tag="h2" :appear="inView" class="preData">
            Thematic Allocation
        </AnimateText>
        <FadeIn tag="table" :appear="inView" class="themAlloc_canvasContainer">
            <DoughnutGraph
                v-if="getThemAllocData.length > 0"
                :data="getThemAllocData"
            />
        </FadeIn>
    </div>
</template>

<script>
import DoughnutGraph from "./DoughnutGraph.vue";
import AnimateText from '@/components/Animate/AnimateText';
import FadeIn from '@/components/Animate/FadeIn';
export default {
    name: "ThemAlloc",
    components: {
        DoughnutGraph,
        AnimateText,
        FadeIn,
    },
    data() {
        return {
            inView: false,
        };
    },
    inject: ['locomotiveScroll'],
    mounted() {
        const scroll = this.locomotiveScroll();
        setTimeout(() => scroll.on('call', (e) => this.themAllocHandler(e)), 100);
    },
    methods: {
        themAllocHandler(caller) {
            let [ func, param ] = caller;
            if (this[func])
                this[func](param);
        },
        onThemAllocAppear() {
            this.inView = true;
        }
    },
    computed: {
        getThemAllocData() {
            return this.$store.state.themAllocData;
        },
    },
};
</script>

<style lang="stylus" scoped>
.themAlloc {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Telegraph';

    &_canvasContainer {
        width: 100%;
    }
}
</style>