<script>
export default {
  name: 'AnimateExpand',
  functional: true,
  render (createElement, context) {
    const data = {
      props: {
        name: 'expand',
      },
      on: {
        afterEnter (element) {
          element.style.height = 'auto';
        },
        enter (element) {
          const { width } = getComputedStyle(element);
          element.style.width = width;
          element.style.position = 'absolute';
          element.style.visibility = 'hidden';
          element.style.height = 'auto';
          const { height } = getComputedStyle(element);
          element.style.width = null;
          element.style.position = null;
          element.style.visibility = null;
          element.style.height = 0;
          // Force repaint to make sure the animation is triggered correctly.
          // eslint-disable-next-line no-unused-expressions
          getComputedStyle(element).height;
          requestAnimationFrame(() => {
            element.style.height = height;
          });
        },
        leave (element) {
          const { height } = getComputedStyle(element);
          element.style.height = height;
          // Force repaint to make sure the animation is triggered correctly.
          // eslint-disable-next-line no-unused-expressions
          getComputedStyle(element).height;
          requestAnimationFrame(() => {
            element.style.height = 0;
          });
        },
      },
    };
    return createElement('transition', data, context.children);
  },
};
</script>

<style lang="stylus">
.expand-enter-active,
.expand-leave-active {
  overflow: hidden;
  transition: height 1s cubic-bezier(0.16, 1, 0.3, 1) 0.15s, opacity 0.25s ease;

  @media (prefers-reduced-motion) {
    transition: none !important;
  }
}

.expand-enter-active {
  transition: height 1s cubic-bezier(0.16, 1, 0.3, 1), opacity 0.5s ease 0.15s;
}

.expand-enter,
.expand-leave-to {
  height: 0;
  opacity: 0;
}
</style>
