<template>
  <div data-scroll-section class="investmentSolutionsCircle">
    <div data-scroll data-scroll-call="onAppearCircle, null" class="investmentSolutionsCircle_inner">
      <div ref="videoContainer" class="investmentSolutionsCircle_video-container">
      <video src="/assets/videos/investment-circle.mp4" type="video/mp4" playsinline loop muted autoplay class="investmentSolutionsCircle_video -desktop" />
      <video src="/assets/videos/investment-circle-mobile.mp4" type="video/mp4" playsinline loop muted autoplay class="investmentSolutionsCircle_video -mobile" />
      </div>
      <div class="investmentSolutionsCircle_circle">
        <div 
          v-for="(link, i) in links"
          ref="link"
          :key="i"
          class="investmentSolutionsCircle_item"
          tabindex="0"
          @click="onLinkClick(link.path)"
        >
          <router-link :to="link.path" class="investmentSolutionsCircle_link" tabindex="-1">
            <img :src="link.iconPath" alt="" class="investmentSolutionsCircle_linkIcon">
            <span class="investmentSolutionsCircle_linkTitle" v-text="link.title" />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap';
import AnimateText from '@/components/Animate/AnimateText';
export default {
  name: "InvestmentSolutionsCircle",
  components: {
    AnimateText
  },
  data () {
    return {
      links: [
        { path: '/investment/gdn', title: 'FinTech', iconPath: '/assets/images/investIcons/FinTech.svg' },
        { path: '/investment/gdn', title: 'Gaming', iconPath: '/assets/images/investIcons/Gaming.svg' },
        { path: '/investment/gdn', title: 'VR', iconPath: '/assets/images/investIcons/VR.svg' },
        { path: '/investment/tms', title: 'Behavioural Finance', iconPath: '/assets/images/investIcons/Behavioural.svg' },
        { path: '/investment/tms', title: 'CTA', iconPath: '/assets/images/investIcons/CTA.svg' },
        { path: '/investment/tms', title: 'S&P', iconPath: '/assets/images/investIcons/SP.svg' },
        { path: '/investment/tms', title: 'Hedge Fund', iconPath: '/assets/images/investIcons/HedgeFund.svg' },
        { path: '/investment/tms', title: 'Quantitative', iconPath: '/assets/images/investIcons/Quantitative.svg' },
        { path: '/investment/tms', title: 'Algorithm', iconPath: '/assets/images/investIcons/Algorithm.svg' },
        { path: '/investment/gdn', title: 'A.I.', iconPath: '/assets/images/investIcons/AI.svg' },
        { path: '/investment/gdn', title: 'Networks', iconPath: '/assets/images/investIcons/Networks.svg' },
      ],
      linksAnimated: false,
    };
  },
  inject: ['locomotiveScroll'],
  mounted() {
    const scroll = this.locomotiveScroll();
    setTimeout(() => scroll.on('call', (e) => this.investmentSolutionsCircleHandler(e)), 100);
  },
  methods: {
    investmentSolutionsCircleHandler(caller) {
      let [ func, param ] = caller;
      if (this[func])
        this[func](param);
    },
    onAppearCircle() {
      if (!this.linksAnimated) {
        const tl = gsap.timeline();
        tl
          .set(this.$refs.link, { rotationX: -90, scale: 0.85, yPercent: 50, opacity: 0 })
          .to(this.$refs.videoContainer, { opacity: 1, duration: 0.5 })
          .to(this.$refs.link, { rotationX: 0, scale: 1, yPercent: 0, opacity: 1, ease: 'power1.out', duration: 0.5, stagger: 0.15 });
        
        this.linksAnimated = true;
      }
    },
    onLinkClick (url) {
      // const hover = window.matchMedia('(hover: hover)').matches;
      // if (!hover)
      //   return;
      // this.$router.push(url);
    }
  },
};
</script>

<style lang="stylus">
.investmentSolutionsCircle {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 5vh var(--respHorPadding) 20vh;
}

.investmentSolutionsCircle_inner {
  position: relative;
  width: 100%;
  max-width: 820px;

  &::before {
    content: "";
    display: block;
    padding-top: 100%;
  }
}

.investmentSolutionsCircle_video-container {
  position: absolute;
  top: 0; left: 0;
  width: 100%; 
  background-color: $charcoal;
  transform: scale(0.7);
  opacity: 0;

  @media (max-width: $breakSm) {
    transform: scale(0.7) translateX(2%);
  }
}

.investmentSolutionsCircle_video {
  width: 100%;
  mix-blend-mode: lighten;

  &.-mobile {
    @media (min-width: $breakSm) {
      display: none;  
    }
  }

  &.-desktop {
    @media (max-width: $breakSm) {
      display: none;  
    }
  }
}

.investmentSolutionsCircle_circle {
  position: absolute;
  top: 0; left: 0; right: 0; bottom: 0;
  border-radius: 50%;
  font-size: 1.25rem;
  pointer-events: none;

  @media (max-width: $breakMd) {
    font-size: 1rem;
  }

  @media (max-width: $breakSm) {
    font-size: 0.75rem;
  }
}

.investmentSolutionsCircle_item {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.5em;
  height: 4.5em;
  pointer-events: auto;
  opacity: 0;
  // cursor: pointer;
  outline: 0 !important;

  &:nth-child(1) { top: 0; left: calc(48% - 2.5em); }
  &:nth-child(2) { top: 7%; left: 65%; }
  &:nth-child(3) { top: 23%; left: 82%; }
  &:nth-child(4) { top: calc(50% - 2.5em); left: calc(100% - 5em); }
  &:nth-child(5) { top: 66%; left: 82%; }
  &:nth-child(6) { top: 83%; left: 63%; }
  &:nth-child(7) { top: calc(99% - 5em); left: calc(45% - 2.5em); }
  &:nth-child(8) { top: 75%; left: 15%; }
  &:nth-child(9) { top: 53%; left: 2%; }
  &:nth-child(10) { top: 28%; left: 5%; }
  &:nth-child(11) { top: 10%; left: 20%; }

  @media (max-width: $breakSm) {
    &:nth-child(1) { top: 0; left: calc(50% - 2.5em); }
    &:nth-child(2) { top: 6%; left: 65%; }
    &:nth-child(3) { top: 22%; left: 82%; }
    &:nth-child(4) { top: calc(52% - 2.5em); left: calc(104% - 5em); }
    &:nth-child(5) { top: 64%; left: 80%; }
    &:nth-child(6) { top: 80%; left: 60%; }
    &:nth-child(7) { top: calc(102% - 5em); left: calc(45% - 2.5em); }
    &:nth-child(8) { top: 74%; left: 12%; }
    &:nth-child(9) { top: 52%; left: 0%; }
    &:nth-child(10) { top: 28%; left: 0%; }
    &:nth-child(11) { top: 8%; left: 14%; }
  }
}

.investmentSolutionsCircle_link {
  display: inline-block;
  padding: 0.75em;
  transition: opacity 0.5s ease;
  pointer-events: none;

  .investmentSolutionsCircle_circle:hover &,
  .investmentSolutionsCircle_circle:focus-within & {
    opacity: 0.5;
  }

  .investmentSolutionsCircle_item:hover &,
  .investmentSolutionsCircle_item:focus & {
    opacity: 1;
  }

  .investmentSolutionsCircle_item:focus &,
  &:focus {
    pointer-events: auto;
  }
}

.investmentSolutionsCircle_linkIcon {
  width: 100%; height: 100%;
  object-fit: contain;
  transform: translateY(0.25em);
  transition: transform 0.25s cubic-bezier(0.22, 1, 0.36, 1);

  .investmentSolutionsCircle_item:hover &,
  .investmentSolutionsCircle_item:focus & {
    transform: translateY(0);  
  }
}

.investmentSolutionsCircle_linkTitle {
  position: absolute;
  top: 100%; left: 50%;
  display: block;
  white-space: nowrap;
  color: $lightBlue;
  font-size: 0.75em;
  transform: translate(-50%, 0.25em);
  opacity: 0;
  transition: opacity 0.25s ease, transform 0.25s cubic-bezier(0.22, 1, 0.36, 1);

  .investmentSolutionsCircle_item:hover &,
  .investmentSolutionsCircle_item:focus & {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}
</style>