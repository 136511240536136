<template>
  <div data-scroll-section class="foundationHeader">
    <PageHeaderBackground bottom-gradient :scroll-speed="-7">
      <video src="/assets/videos/foundation-bg.mp4" type="video/mp4" class="foundationHeader_background -desktop" playsinline loop muted autoplay />
      <video src="/assets/videos/foundation-bg-mobile.mp4" type="video/mp4" class="foundationHeader_background -mobile" playsinline loop muted autoplay />
    </PageHeaderBackground>
    <div class="foundationHeader_content">
      <div class="foundationHeader_contentHeading">
        <AnimateText tag="h1" appear class="typeH2" v-text="title" />
      </div>
      <FadeIn tag="div" appear :delay="0.5" class="foundationHeader_contentText">
        <prismic-rich-text :field="lead" />
      </FadeIn>
    </div>
  </div>
</template>

<script>
import PageHeaderBackground from '@/components/UI/PageHeaderBackground';
import AnimateText from '@/components/Animate/AnimateText';
import FadeIn from '@/components/Animate/FadeIn';
export default {
  name: "FoundationHeader",
  components: {
    PageHeaderBackground,
    AnimateText,
    FadeIn,
  },
  props: {
    title: String,
    lead: Array,
  }
};
</script>

<style lang="stylus">
.foundationHeader {
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 35vh var(--respHorPadding) 25vh;

  @media (max-width: $breakMd) {
    padding: 20vh var(--respHorPadding) 10vh;
  }
}

.foundationHeader_background {
  mix-blend-mode: lighten;

  &.-mobile {
    @media (min-width: $breakSm) {
      display: none;  
    }
  }

  &.-desktop {
    @media (max-width: $breakSm) {
      display: none;  
    }
  }
}

.foundationHeader_content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
}

.foundationHeader_contentHeading {
  align-self: flex-start;
  display: inline-block;
  flex: 0 0 auto;
  width: 40%;
  margin-bottom: 3rem;

  @media (max-width: $breakMd) {
    width: 67%;
    margin-bottom: 1rem;
  }
}

.foundationHeader_contentText {
  width: 65ch;
  padding-top: 1em;

  p {
    GradientText();
    display: block;
    margin-bottom: 1.25em;
    font-size: 1.5em;
    line-height: 1.4;

    @media (max-width: $breakMd) {
      font-size: 1.25em;
    }
  }

  strong {
    font-weight: 400;
  }

}
</style>