<template>
  <router-link
    v-if="link.path"
    :to="link.path"
    class="mobileNavLink"
  >
    <AnimateText tag="div" :appear="mobileNavOpen" :delay="0.5 + 0.05 * index">
      <span class="mobileNavLink_text" v-text="link.title" />
    </AnimateText>
  </router-link>
  <div v-else>
    <button class="mobileNavLink -button" @click="toggleSubList">
      <AnimateText tag="div" :appear="mobileNavOpen" :delay="0.5 + 0.05 * index">
        <span class="mobileNavLink_text" v-text="link.title" />
      </AnimateText>
    </button>
    <AnimateExpand>
      <div v-show="subListOpen && mobileNavOpen">
        <ul class="mobileNavLink_sublist">
          <li v-for="(subpage, i) in link.children" :key="i">
            <router-link :to="subpage.path" v-text="subpage.title" />
          </li>
        </ul>
      </div>
    </AnimateExpand>
  </div>
</template>

<script>
import { state } from './MobileNavState';
import AnimateText from '@/components/Animate/AnimateText.vue';
import AnimateExpand from '@/components/Animate/AnimateExpand.vue';

export default {
  name: 'MobileNavLink',
  components: {
    AnimateText,
    AnimateExpand,
  },
  props: {
    link: Object,
    index: Number,
  },
  data () {
    return {
      subListOpen: false,
    };
  },
  computed: {
    mobileNavOpen () { return state.mobileNavOpen },
  },
  methods: {
    toggleSubList () {
      this.subListOpen = !this.subListOpen;
    }
  },
}
</script>

<style lang="stylus">
.mobileNavLink {
  display: inline-block;
  width: 100%;
  padding: 1.25em 0;
  border: none;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  text-align: left;
  cursor: pointer;
}

.mobileNavLink_text {
  GradientText();
  display: inline-block;
  font-weight: 200;
  font-family: 'GTFlexa';
  background-size: 90vw 100%;
  font-size: 2.5em;

  @media (max-width: $breakSm) {
    font-size: 1.75em;
  }
}

.mobileNavLink_sublist {
  padding: 0 1.25em 0.25em;
  font-size: 1.25em;
  line-height: 1.2;
  font-family: 'GTFlexa';
  font-weight 200;

  a {
    display: inline-block;
    padding: 0.25em 0;
    color: $blue;
  }
}
</style>