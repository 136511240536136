<template>
  <div ref="inLink" class="inLink">
    <router-link class="inLink_but" :to="toLink">
      <div class="inLink_but_text" ref="ctaText">
        <FadeLink color="light" v-text="textCont" />
      </div>
      <div class="inLink_but_arrows">
        <div class="inLink_but_arrows_slider" ref="ctaArrow">
          <img src="/assets/images/CTA_Arrow.svg" alt="enterArrow" />
          <img src="/assets/images/CTA_Arrow.svg" alt="enterArrow" />
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import FadeLink from "@/components/Animate/FadeLink.vue";
export default {
  name: "InLink",
  props: {
    textCont: String,
    toLink: String
  },
  components: {
    FadeLink,
  },
  mounted() {
    this.$refs.inLink.addEventListener("mouseenter", this.onEnter);
    this.$refs.inLink.addEventListener("mouseleave", this.onLeave);
  },
  methods: {
    onAppear(el) {
      el.classList.add("on");
    },
    onEnter() {
      this.$refs.ctaArrow.classList.add("in");
      this.$refs.ctaText.classList.add("in");
    },
    onLeave() {
      this.$refs.ctaText.classList.remove("in");
      this.$refs.ctaArrow.classList.remove("in");
    }
  }
};
</script>

<style lang="stylus" scoped>
.inLink {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  font-family: 'Telegraph';
  font-weight: 200;

  &:hover {
    cursor: pointer;
  }

  &_but {
    position: relative;
    z-index: 1;
    background: none;
    border: none;
    padding: 5px 0;
    font-weight: 200;
    font-size: 2em;
    @media (max-width: $breakMd) { font-size: 1.75em; }
    @media (max-width: $breakSm) { font-size: 1.5em; }
    font-family: inherit;
    cursor: pointer;
    display: flex;
    align-items: center;

    &_text {
      margin-right: 10px;
      transform: translateX(0px);
      transition: transform 0.3s;

      &.in {
        transform: translateX(-10px);
      }
    }

    &_arrows {
      width: 30px;
      overflow: hidden;

      &_slider {
        width: 100%;
        transform: translate3d(-100%, 0, 0);
        display: flex;
        flex-wrap: nowrap;

        img {
          flex: 1 0 100%;
        }

        &.in {
          transition: all 0.3s;
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }
}
</style>