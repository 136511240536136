<template>
  <div data-scroll-section class="news-list">
    <NewsListItem
      v-for="post in posts"
      :key="post.id"
      :uid="post.uid"
      :title="$prismic.asText(post.data.title)"
      :category="post.data.category"
      :date="post.data.date_published"
      :content="$prismic.asText(post.data.resume)"
    />
  </div>
</template>

<script>
import NewsListItem from "@/components/News/NewsListItem.vue";
export default {
  name: "News",
  components: {
    NewsListItem,
  },
  props: {
    posts: Array,
  },
};
</script>

<style lang="stylus">
.news-list {
  padding: 0 var(--respHorPadding) 12vh;
}
</style>