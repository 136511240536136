<template>
  <div class="disclaimer">
    <div data-scroll-section class="disclaimer_header">
      <PageHeaderBackground bottom-gradient>
        <video src="/assets/videos/investment-products-bg.mp4" type="video/mp4" class="disclaimer_background -desktop" playsinline loop muted autoplay />
        <video src="/assets/videos/investment-products-bg-mobile.mp4" type="video/mp4" class="disclaimer_background -mobile" playsinline loop muted autoplay />
        <div class="disclaimer_backgroundGradient" />
      </PageHeaderBackground>
    </div>
    <div data-scroll-section class="disclaimer_inner">
      <AnimateText tag="h1" appear class="typeH2 disclaimer_heading">
        <span class="typeGradient">Disclaimer</span>
      </AnimateText>
      <div class="disclaimer_text">
        <div class="disclaimer_textCol">
          <p><strong>Please read the below important legal information. Only if you meet the terms set out below
            you may click "I Accept" to continue.

          </strong></p>
          <p>
            The information contained on this website is strictly only for qualified and professional investors.
            Furthermore the information is not intended for investors who are domiciled in the United States of
            America (USA) or are otherwise deemed a US Person. 
          </p>
          <p>
            For investors in all other jurisdictions, this website is neither directed to, nor intended for
            distribution or use by, any person or entity who is a citizen or resident of or located in any
            locality, state, country or jurisdiction where such distribution, publication, availability or
            use would be contrary to local law or regulation.
          </p>
          <p>
            The information contained on this website relates to investment funds and investment products managed
            by Taranis SA. The information is not intended, and does not qualify as, a general or personal
            investment recommendation or advice and is provided for information purposes only. In particular,
            the information does not constitute a public offer, solicitation or recommendation to buy or sell
            financial instruments. 
          </p>
        </div>
        <div class="disclaimer_textCol">
          <p>
            The information is not tailored for any particular investor, and does not otherwise constitute, and
            may not be construed as, individual investment advice. Before making any investment decision,
            investors are recommended to ascertain if this investment is suitable for them in light of their
            financial knowledge and experience, investment goals and financial situation, and/or to obtain
            specific advice from an industry professional.
          </p>
          <p>
            All information contained on this website is intended solely for the use of the person to whom
            it is made available and may not be reproduced, copied or given to any other person. 
          </p>
          <p>
            No representation, warranty or undertaking is given by Taranis SA that the information contained
            in this website is accurate, complete, comprehensive or up to date; no liability is accepted
            by Taranis SA for such information. The source of data is Taranis SA, unless otherwise stated.
          </p>
          <p>
            Past performance is not indicative of future performance. The price, value and income from any
            investments may fall as well as rise and may be affected by changes in foreign exchange rates.
            An investor may not get back the amount invested.  
          </p>
        </div>
      </div>
      <div class="disclaimer_accept">
        <p><strong>
          Taranis SA cannot take any responsibility for individuals accessing this website by giving false
          information as to their domicile or client type or as a consequence of having disseminated
          information contained on this website.
        </strong></p>
        <p><strong>By clicking "I Accept" you confirm that you:</strong></p>
        <ul>
          <li>
            Have read and understood the important legal information stated above.
          </li>
          <li>
            Are not domiciled in the USA or deemed a US Person.
          </li>
        </ul>
        <div class="disclaimer_buttonsContainer">
          <button class="disclaimer_button" @click="accept">Accept</button>
          <button class="disclaimer_button" @click="decline">Decline</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeaderBackground from '@/components/UI/PageHeaderBackground';
import AnimateText from '@/components/Animate/AnimateText.vue';
import FadeIn from '@/components/Animate/FadeIn.vue';
export default {
  name: 'DisclaimerText',
  components: {
    PageHeaderBackground,
    AnimateText,
    FadeIn
  },
  data () {
    return {
      scroll: null,
    };
  },
  inject: ['locomotiveScroll'],
  mounted() {
    this.scroll = this.locomotiveScroll();
  },
  methods: {
    accept () {
      this.$store.commit('setDisclaimerGDN', {
        answered: true,
        accepted: true,
        forbidden: false,
      });
      setTimeout(() => {
        this.scroll.scrollTo("top", { duration: 0, disableLerp: true });
        this.scroll.update();
      }, 500);
    },
    decline () {
      this.$store.commit('setDisclaimerGDN', {
        answered: true,
        accepted: false,
        forbidden: true,
      });
    }
  }
}
</script>

<style lang="stylus" scoped>
.disclaimer_header {
  position: absolute; width: 100%; height: 100vh;
}
.disclaimer_inner {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20vh var(--respHorPadding) 10vh;
}

.disclaimer_heading {
  display: inline-block;
  margin-right: auto;
  margin-bottom: 0.75em;
}

.disclaimer_text,
.disclaimer_accept {
  margin-bottom: 3em;
  font-size: 1.2em;
  font-family: "Telegraph";
  font-weight 200;
  color: $lightBlue !important;

  @media (max-width: $breakMd) { font-size: 1em; }
  
  p {
    font: inherit;
    color: $lightBlue !important;
    margin-bottom: 1em;
    line-height: 1.4;
  }

  strong {
    color: $lightBlue !important;
    font-weight: 400;
  }
}

.disclaimer_text {
  display: flex;
  flex-direction: column;

  @media (min-width: $breakMd) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.disclaimer_textCol {
  @media (min-width: $breakMd) {
    width: 47%;
  }
}

.disclaimer_accept {

  @media (min-width: $breakMd) {
    width: 47%;
    margin-right: auto;
  }

  p {
    margin-bottom: 1em;
  }

  ul li {
    position: relative;
    color: $lightBlue;
    margin-bottom: 1em;
    padding-left: 1em;

    &::before {
      content: "-";
      display: inline-block;
      position: absolute;
      top: 0; left: 0;
    }
  }
}

.disclaimer_buttonsContainer {
  display: flex;
  margin-top: 3em;

  @media (max-width: $breakSm) {
    justify-content: space-between;
  }
}

.disclaimer_button {
  position: relative;
  overflow: hidden;
  width: auto;
  min-width: 14rem;
  padding: 1em;
  border: 1px solid $blue;
  border-radius: 10px;
  background: transparent;
  color: $blue;
  font-size: 1.125rem;
  font-family: "Telegraph";
  line-height: 1;
  cursor: pointer;
  margin-right: 4em;
  transition: color 0.05s 0.1s;
  transform: translate3d(0, 0, 0);

  @media (max-width: $breakSm) {
    min-width: 0;
    width: 48%;
    flex: 0 0 48%;
    margin: 0;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    top: 0; right: 0; bottom: 0; left: 0;
    background-color: $blue;
    transform: scaleX(0);
    transform-origin: center right;
    transition: transform 0.3s;
  }

  &:hover {
    color: $charcoal;

    &::before {
      transform-origin: center left;
      transform: scaleX(1);
    }
  }
}

.disclaimer_background {
  mix-blend-mode: lighten;

  &.-mobile {
    @media (min-width: $breakSm) {
      display: none;  
    }
  }

  &.-desktop {
    @media (max-width: $breakSm) {
      display: none;  
    }
  }
}

.disclaimer_backgroundGradient {
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  background-image: linear-gradient(to right, rgba($charcoal, 0.85), rgba($charcoal, 0) 75%);
}
</style>