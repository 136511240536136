<template>
    <div
        data-scroll
        data-scroll-call="onPerfTableAppear, null"
        class="perfTable"
    >
        <AnimateText tag="h2" :appear="inView" class="preData">
            Performance Table
        </AnimateText>
        <FadeIn :appear="inView" class="perfTable_container">
            <div class="perfTable_containerScroller">
                <table>
                    <thead>
                        <th v-if="this.$route.name == 'tms'" class="left">
                            Taranis Market Sentiment
                            <br />Class A
                        </th>
                        <th v-else class="left">Global Data Network</th>
                        <th>Jan</th>
                        <th>Feb</th>
                        <th>Mar</th>
                        <th>Apr</th>
                        <th>May</th>
                        <th>Jun</th>
                        <th>Jul</th>
                        <th>Aug</th>
                        <th>Sep</th>
                        <th>Oct</th>
                        <th>Nov</th>
                        <th>Dec</th>
                        <th>YTD</th>
                    </thead>
                    <tr>
                        <th class="left">2023</th>
                        <td v-for="(info, i) in data" :key="i">
                            <div v-if="info.year2023">
                                {{ info.year2023.toFixed(1) }}%
                            </div>
                            <div v-else>-</div>
                        </td>
                    </tr>
                    <tr>
                        <th class="left">2022</th>
                        <td v-for="(info, i) in data" :key="i">
                            <div v-if="info.year2022">
                                {{ info.year2022.toFixed(1) }}%
                            </div>
                            <div v-else>-</div>
                        </td>
                    </tr>
                    <tr>
                        <th class="left">2021</th>
                        <td v-for="(info, i) in data" :key="i">
                            <div v-if="info.year2021">
                                {{ info.year2021.toFixed(1) }}%
                            </div>
                            <div v-else>-</div>
                        </td>
                    </tr>
                    <tr v-if="!isGDN">
                        <th class="left last"><span style="margin-right: 0.25em">2020</span></th>
                        <td v-for="(info, i) in data" :key="i">
                            <div v-if="info.year2020">{{ info.year2020 }}%</div>
                            <div v-else>-</div>
                        </td>
                    </tr>
                </table>
            </div>
        </FadeIn>
    </div>
</template>

<script>
import AnimateText from "@/components/Animate/AnimateText";
import FadeIn from "@/components/Animate/FadeIn";
export default {
    name: "PerfTable",
    data() {
        return {
            isGDN: false,
            inView: false,
        };
    },
    props: {
        data: [],
    },
    components: {
        AnimateText,
        FadeIn,
    },
    inject: ["locomotiveScroll"],
    mounted() {
        const scroll = this.locomotiveScroll();
        setTimeout(
            () => scroll.on("call", (e) => this.perfTableHandler(e)),
            100
        );

        if (this.$route.name == "gdn") {
            this.isGDN = true;
        }
    },
    methods: {
        perfTableHandler(caller) {
            let [func, param] = caller;
            if (this[func]) this[func](param);
        },
        onPerfTableAppear() {
            this.inView = true;
        },
    },
};
</script>

<style lang="stylus" scoped>
.perfTable {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Telegraph';

    table {
        width: 100%;
        border-collapse: collapse;

        @media (max-width: $breakSm) {
            font-size: 0.75rem;
        }

        thead {
            th {
                border-bottom: solid #0e3440 0.2px;
                font-weight: 200;
                text-align: center;
                color: $blue;
                padding: 20px 10px;

                &.left {
                    position: sticky;
                    left: 0;
                    vertical-align: bottom;
                    text-align: left;
                    font-weight: 200;
                    padding: 20px 10px 20px 0;
                    border-bottom: solid #0e3440 0.2px;
                    background: $charcoal;
                }
            }
        }

        tr {
            th {
                &.last {
                    &:after {
                        display: inline-block;
                        white-space: nowrap;
                        content: '(from Dec 28th)';
                        font-size: 0.6rem;
                        vertical-align: text-top;

                        @media (max-width: $breakSm) {
                            display: block;
                        }
                    }
                }

                &.left {
                    position: sticky;
                    left: 0;
                    text-align: left;
                    font-weight: 200;
                    padding: 20px 0;
                    border-bottom: solid #0e3440 0.2px;
                    background: $charcoal;
                }
            }

            td {
                font-size: 0.8rem;
                border-bottom: solid #0e3440 0.2px;
                font-weight: 200;
                text-align: center;
            }
        }
    }
}

.perfTable_container {
    position: relative;
    display: block;
    width: 100%;

    @media (max-width: $breakSm) {
        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 10vw;
            pointer-events: none;
            background: linear-gradient(to right, rgba($charcoal, 0), rgba($charcoal, 1));
        }
    }
}

.perfTable_containerScroller {
    position: relative;
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}
</style>