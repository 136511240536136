<template>
  <div data-scroll-section class="homeHeader">
    <!-- <PageHeaderBackground bottom-gradient>
      <video
        src="/assets/videos/contact-bg.mp4"
        type="video/mp4"
        class="homeHeader_backgroundImage -desktop"
        playsinline
        loop
        muted
        autoplay
      />
      <video
        src="/assets/videos/contact-bg-mobile.mp4"
        type="video/mp4"
        class="homeHeader_backgroundImage -mobile"
        playsinline
        loop
        muted
        autoplay
      />
    </PageHeaderBackground>-->
    <div class="homeHeader_threeScene">
      <ThreeScene />
    </div>
    <AnimateText data-scroll tag="p" appear class="homeHeader_introText typeH2">
      We
      <strong>
        <span>
          <ul ref="rotWords">
            <li class="aft" ref="rotWordsAft"></li>
            <li ref="rotWordsBef"></li>
          </ul>
        </span>
      </strong>
      <strong>patterns</strong>
      within financial markets, human behaviour and technological
      innovation to anticipate trends.
    </AnimateText>
    <SideLine class="homeHeader_sideLine" />
  </div>
</template>

<script>
import gsap from "gsap";
import PageHeaderBackground from "@/components/UI/PageHeaderBackground";
import ThreeScene from "./ThreeScene";
import AnimateText from "@/components/Animate/AnimateText";
import SideLine from "@/components/UI/SideLine.vue";
export default {
  name: "HomeHeader",
  components: {
    PageHeaderBackground,
    AnimateText,
    ThreeScene,
    SideLine
  },
  data() {
    return {
      swappingTextBef: "discover",
      swappingTextAft: "discover",
      wordSwappTimer: null
    };
  },
  mounted() {
    [..."discover"].forEach(char => {
      const letter = document.createElement("strong");
      letter.style = "font-weight: 400;";
      letter.textContent = char;
      this.$refs.rotWordsBef.appendChild(letter);
    });

    const wordList = ["identify", "analyze", "discover", "explore"];
    let i = 0;
    this.wordSwappTimer = setInterval(() => {
      this.$refs.rotWordsBef.innerHTML = "";
      [...this.swappingTextAft].forEach(char => {
        const letter = document.createElement("strong");
        letter.style = "font-weight: 400; display: inline-block;";
        letter.textContent = char;
        this.$refs.rotWordsBef.appendChild(letter);
      });
      this.swappingTextBef = this.swappingTextAft;

      this.$refs.rotWordsAft.innerHTML = "";
      [...wordList[i % wordList.length]].forEach(char => {
        const letter = document.createElement("strong");
        letter.style = "font-weight: 400; display: inline-block;";
        letter.textContent = char;
        this.$refs.rotWordsAft.appendChild(letter);
      });
      this.swappingTextAft = wordList[i % wordList.length];
      this.rotateWords();
      i++;
    }, 4000);
  },

  beforeDestroy() {
    clearInterval(this.wordSwappTimer);
  },
  methods: {
    rotateWords() {
      const letterTime = 0.4;
      const letterDelayFact = 0.05;
      const scdWordDelay = 0.3;
      this.$refs.rotWordsBef.children.forEach((el, i) => {
        gsap.fromTo(
          el,
          letterTime,
          {
            opacity: 1
          },
          {
            opacity: 0,
            delay:
              (this.$refs.rotWordsBef.children.length - i) * letterDelayFact
          }
        );
      });

      this.$refs.rotWordsAft.children.forEach((el, i) => {
        gsap.fromTo(
          el,
          letterTime,
          {
            opacity: 0
          },
          {
            opacity: 1,
            delay:
              i * letterDelayFact +
              this.$refs.rotWordsBef.children.length * letterDelayFact +
              scdWordDelay
          }
        );
      });
    }
  },
  beforedestroyed() {
    clearInterval(this.wordSwappTimer);
  }
};
</script>

<style lang="stylus">
.homeHeader {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  padding: var(--respHorPadding);
  position: relative;

  &_threeScene {
    position: absolute;
    top: 0;
    left: 0;
  }

  &_introText {
    width: 66%;

    @media (max-width: $breakMd) {
      width: 80%;
    }

    @media (max-width: $breakSm) {
      width: 90%;
      font-size: 1.75em !important;
    }

    > div {
      GradientText();
      background-size: 200% 200%;
      -webkit-animation: gradientAnimation 8s ease infinite;
      -moz-animation: gradientAnimation 8s ease infinite;
      animation: gradientAnimation 8s ease infinite;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    strong {
      font-weight: 400;

      span {
        position: relative;
        display: inline-block;
        width: 6.5ch;
        transform: translateX(-0.2ch);

        ul {
          display: flex;
          justify-content: center;

          li {
            -webkit-text-fill-color: $lightBlue;
            display: flex;
            flex-wrap: nowrap;

            &.aft {
              position: absolute;
            }
          }
        }
      }
    }
  }

  @keyframes gradientAnimation {
    0% {
      background-rotat: 0% 0%;
    }

    50% {
      background-position: 100% 100%;
    }

    100% {
      background-position: 0% 0%;
    }
  }
}

.homeHeader_sideLine {
  position: absolute;
  bottom: -33px;
  left: var(--respHorPadding);
}

.homeHeader_backgroundImage {
  opacity: 0.35;

  &.-mobile {
    @media (min-aspect-ratio: 1 / 1) {
      display: none;
    }
  }

  &.-desktop {
    @media (max-aspect-ratio: 1 / 1) {
      display: none;
    }
  }
}
</style>
