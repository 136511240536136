<template>
  <div class="sideLine" ref="sideLine">
    <div class="sideLine_fill"></div>
  </div>
</template>

<script>
import { EventBus } from '@/eventBus';
export default {
  name: "SideLine",
  mounted () {
    setTimeout(() => {
      EventBus.$on('scrollPos', this.onScroll);
    }, 1000);
  },
  beforeDestroy () {
    EventBus.$off('scrollPos', this.onScroll);
  },
  methods: {
    onScroll (e) {
      if (e > 0) {
        this.$refs.sideLine.classList.add("off");
        EventBus.$off('scrollPos', this.onScroll);
      }
    },
  },
};
</script>

<style lang="stylus">
.sideLine {
  width: 1px;
  height: 100px;
  overflow: hidden;
  opacity: 1;
  transition: opacity 0.3s;

  &_fill {
    width: 100%;
    height: 100%;
    background: $lightBlue;
    animation: scrollDown infinite 4s;
  }

  &.off {
    opacity: 0;
  }

  @keyframes scrollDown {
    0% {
      transform: translateY(-100%);
    }

    30% {
      transform: translateY(100%);
    }

    100% {
      transform: translateY(100%);
    }
  }
}
</style>
