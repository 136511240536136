<template>
  <component
    :is="tag"
    ref="text"
    class="animateTextChars"
  >
  <slot />
  </component>
</template>

<script>
import { gsap } from 'gsap';
import { SplitText } from '@/assets/js/gsap/SplitText';
gsap.registerPlugin(SplitText);

export default {
  name: 'AnimateTextChars',
  props: {
    tag: {
      type: String,
      default: 'span',
    },
    duration: {
      type: Number,
      default: 0.25
    },
    delay: {
      type: Number,
      default: 0,
    },
    stagger: {
      type: Number,
      default: 0.04,
    },
    appear: Boolean,
  },
  data () {
    return {
      text: '',
      tl: null,
    };
  },
  computed: {
    readyToAnimate () {
      return this.appear;
    }
  },
  watch: {
    appear (newAppear) {
      newAppear ? this.in() : this.out();
    }
  },
  mounted () {
    this.text = new SplitText(this.$refs.text, { type: 'words, chars', charsClass: 'char' });
    this.tl = gsap.timeline({ paused: true, defaults: { stagger: this.stagger } });
    this.tl
        .set(this.text.chars, { opacity: 0 })
        .set(this.$refs.text, { opacity: 1 })
        .to(this.text.chars, { opacity: 1, duration: this.duration, ease: 'linear' });

    if (this.readyToAnimate)
      gsap.delayedCall(this.delay, this.in);
  },
  methods: {
    in () {
      gsap.delayedCall(0.35, () => this.tl.play(0));
    },
    out () {
      this.tl.reverse(0);
    },
  },
};
</script>

<style lang="stylus">
.animateTextChars {
  opacity: 0;
}
</style>
