<template>
  <div 
    ref="container"
    data-scroll
    data-scroll-repeat
    data-scroll-offset="10%, 10%"
    data-scroll-call="onLogoAppear, null"
    class="animated-logotype"
  >
    <div ref="lottieEl" class="animated-logotype_lottie" />
  </div>
</template>

<script>
const lottie = require('lottie-web');
export default {
  name: "AnimatedLogotype",
  data () {
    return {
      lottieVideo: null,
      inView: false,
    };
  },
  inject: ["locomotiveScroll"],
  mounted() {
    const scroll = this.locomotiveScroll();
    setTimeout(() => scroll.on("call", (e) => this.logoAppearHandler(e)), 100);

    this.lottieVideo = lottie.loadAnimation({
      container: this.$refs.lottieEl,
      renderer: 'svg',
      autoplay: false,
      loop: false,
      path: '/assets/lottie/animatedLogotype/data.json',
    });
  },
  methods: {
    revealLogotype () {
      this.lottieVideo.playSegments([0, 160], true);
    },
    hideLogotype () {
      this.lottieVideo.goToAndStop(0, true);
    },
    logoAppearHandler (caller) {
      let [func, param] = caller;
      if (this[func]) this[func](param);
    },
    onLogoAppear () {
      setTimeout(() => {
        const inViewClass = this.$refs.container.classList.contains('is-inview');

        if (this.inView === inViewClass) {
          return;
        } else {
          this.inView = inViewClass;
          if (this.inView) {
            this.revealLogotype();
          } else {
            this.hideLogotype();
          }
        }
      }, 10);
    },
  },
}
</script>

<style lang="stylus">
.animated-logotype {
  position: relative;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  width: 83ch;
  max-width: 100%;
  padding: 0 var(--respHorPadding);

  .animated-logotype_lottie {
    width: 60%;
  }

  &::after {
    content: "";
    position: absolute;
    top: -15vw; right: -15vw; bottom: -15vw; left: -15vw;
    z-index: -1;
    background-image: radial-gradient(closest-side, rgba(#00657b, 0.25), rgba(#00657b, 0));
  }
}
</style>