<template>
  <div v-if="document" class="foundation">
    <FoundationHeader :title="$prismic.asText(document.title)" :lead="document.header_paragraph" />
    <div data-scroll-section class="foundation_main">
      <div class="foundation_god-illustration" data-scroll data-scroll-speed="-2" />
      <div class="foundation_main-inner" data-scroll>
        <div class="newsHeader_contentHeading">
          <AnimateText
            tag="h2"
            class="typeH2"
            data-scroll
            data-scroll-call="onAppearP1, null"
            :appear="appearP1"
            v-text="$prismic.asText(document.subheading)"
          />
        </div>
        <FadeIn :delay="0.25" :appear="appearP1">
          <prismic-rich-text :field="document.paragraph_1" />
        </FadeIn>
        <FadeIn :delay="0.25" data-scroll data-scroll-call="onAppearP2, null" :appear="appearP2" class="foundation_patterns">
            <img src="/assets/images/foundation-patterns-wheels.png" alt="" />
            <img src="/assets/images/foundation-patterns.png" alt="" />
        </FadeIn>
        <FadeIn data-scroll data-scroll-call="onAppearP3, null" :appear="appearP3">
          <prismic-rich-text :field="document.paragraph_2" />
        </FadeIn>
      </div>
    </div>
    <div data-scroll-section>
      <AnimatedLogotype />
    </div>
    <div data-scroll-section class="foundation_cta">
      <InLink textCont="Contact Us" toLink="/contact" />
    </div>
  </div>
</template>

<script>
import FoundationHeader from "@/components/Foundation/FoundationHeader.vue";
import AnimatedLogotype from "@/components/Foundation/AnimatedLogotype.vue";
import InLink from "../components/UI/InLink.vue";
import AnimateText from '@/components/Animate/AnimateText';
import FadeIn from '@/components/Animate/FadeIn';
export default {
  name: "OurTechnology",
  components: {
    FoundationHeader,
    AnimatedLogotype,
    InLink,
    AnimateText,
    FadeIn,
  },
  data() {
    return {
      document: null,
      appearP1: false,
      appearP2: false,
      appearP3: false,
      appearP4: false,
    };
  },
  inject: ['locomotiveScroll'],
  created () {
    this.getContent();
  },
  mounted() {
    const scroll = this.locomotiveScroll();
    setTimeout(() => scroll.on('call', (e) => this.foundationHandler(e)), 100);
  },
  methods: {
    async getContent() {
      const document = await this.$prismic.client.getSingle('foundation_page');
      this.document = document.data;
      setTimeout(() => {
        const scroll = this.locomotiveScroll();
        scroll.start();
      }, 500);
    },
    foundationHandler(caller) {
      let [ func, param ] = caller;
      if (this[func])
        this[func](param);
    },
    onAppearP1 () {
      this.appearP1 = true;
    },
    onAppearP2 () {
      this.appearP2 = true;
    },
    onAppearP3 () {
      this.appearP3 = true;
    },
    onAppearP4 () {
      this.appearP4 = true;
    },
  },
};
</script>

<style lang="stylus">
.foundation {
  font-family: 'Telegraph';
  font-weight: 200;

  .typeH2 div {
    GradientText();
  }
}

.foundation_main {
  position: relative;
  display: flex;
  justify-content: center;
}

.foundation_god-illustration {
  position: absolute;
  top: 0; left: 0;
  width: 100%;
  height: 31vw;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    width: 17vw;
    padding-top: 31vw;
    background: transparent url('/assets/images/taranis-god.png') no-repeat;
    background-size: 200% auto;

    @media (max-width: $breakSm) {
      width: 30vw;
      padding-top: 55vw;
    }
  }

  &::after {
    right: 0;

    @media (max-width: $breakSm) {
      transform: translateY(-75%);
    }
  }

  &::before {
    left: 0;
    background-position: 100% 0;

    @media (max-width: $breakSm) {
      transform: translateY(-40%);
    }
  }
}

.foundation_main-inner {
  display: flex;
  flex-direction: column;
  width: 83ch;
  max-width: 100%;
  padding: 0 var(--respHorPadding);

  p {
    GradientText();
    display: block;
    margin-bottom: 1.25em;
    font-size: 1.5em;
    line-height: 1.4;

    @media (max-width: $breakMd) {
      font-size: 1.25em;
    }
  }

  img {
    width: 100%;
    height: auto;
    margin-bottom: 1em;
  }
}

.foundation_patterns {
  margin: 3em 0;
}

.foundation_cta {
  padding-top: 30vh;
}
</style>