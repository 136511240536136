<template>
  <div data-scroll data-scroll-id="approachVideo" data-scroll-offset="0,0" class="ourTechApproach_videoContainer">
    <div ref="lottieEl" class="ourTechApproach_lottie" />
  </div>
</template>

<script>
const lottie = require('lottie-web');
export default {
  name: 'OurTechApproachVideo',
  data () {
    return {
      progress: 0,
      frames: 0,
      lottieVideo: null,
    };
  },
  inject: ['locomotiveScroll'],
  mounted () {
    this.lottieVideo = lottie.loadAnimation({
      container: this.$refs.lottieEl,
      renderer: 'canvas',
      autoplay: false,
      loop: false,
      path: '/assets/lottie/techApproach/data.json',
    });
    this.lottieVideo.addEventListener('DOMLoaded', this.setFrames);

    const scroll = this.locomotiveScroll();
    scroll.on('scroll', (args) => {
      const element = args.currentElements['approachVideo'];
      if (typeof element === 'object') {
        this.progress = element.progress;
        const currentFrame = Math.round(this.frames * this.progress);
        this.lottieVideo.goToAndStop(currentFrame, true);
      }
    });
  },
  methods: {
    setFrames () {
      this.frames = this.lottieVideo.getDuration(true);
      this.lottieVideo.removeEventListener('DOMLoaded', this.setFrames);
    }
  },
};
</script>

<style lang="stylus">
.ourTechApproach_videoContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: $charcoal;
  overflow: hidden;

  .ourTechApproach_lottie {
    flex: 0 0 auto;
    width: 100%;
    mix-blend-mode: lighten;

    @media (max-width: $breakSm) {
      width: 150%;
    }
  }
}
</style>