<template>
  <div v-if="document" class="GDN">
    <div data-scroll-section class="GDN_header">
      <PageHeaderBackground bottom-gradient>
        <video src="/assets/videos/investment-products-bg.mp4" type="video/mp4" class="GDN_background -desktop" playsinline loop muted autoplay />
        <video src="/assets/videos/investment-products-bg-mobile.mp4" type="video/mp4" class="GDN_background -mobile" playsinline loop muted autoplay />
        <div class="GDN_backgroundGradient" />
      </PageHeaderBackground>
      <AnimateText tag="h1" appear class="GDN_headerTitle typeH2" v-text="$prismic.asText(document.title)" />
      <FadeIn appear :delay="0.5">
        <prismic-rich-text :field="document.lead" />
      </FadeIn>
    </div>
    <div data-scroll-section class="GDN_row">
      <div class="GDN_feesGraph" data-scroll ref="feesGraph">
        <FeesGraph v-if="getGDNData.length > 0" :data="getGDNData" />
      </div>
      <div class="GDN_themAlloc" data-scroll ref="themAlloc">
        <ThemAlloc v-if="getThemAllocData.length > 0" :data="getThemAllocData" />
      </div>
    </div>
    <div data-scroll-section class="GDN_row">
      <div class="GDN_perfTable" data-scroll ref="perfTable">
        <PerfTable v-if="getGDNPerfTableData.length > 0" :data="getGDNPerfTableData" />
      </div>
      <div class="GDN_currAlloc" data-scroll ref="currAlloc">
        <CurrencyAlloc />
      </div>
    </div>
    <div data-scroll-section class="GDN_row">
      <div class="GDN_geoThem" data-scroll ref="geoTheme">
        <GeoAlloc :mapImage="document.geographical_allocation_map" />
      </div>
      <div class="GDN_markCap" data-scroll ref="markCap">
        <MarkCapAlloc />
      </div>
    </div>
    <div data-scroll-section class="GDN_termsContainer">
      <h3 class="GDN_shortDisclaimer">Past performance is not indicative of future results.</h3>
      <div class="GDN_terms" data-scroll ref="terms">
        <TermsDetails v-if="getGDNTerms.length > 0" :data="getGDNTerms" />
      </div>
    </div>
    <div data-scroll-section class="GDN_TMS">
      <InLink textCont="Taranis Market Sentiment" toLink="/investment/tms" />
    </div>
  </div>
</template>

<script>
import FeesGraph from "./FeesGraph";
import PerfTable from "./PerfTable";
import ThemAlloc from "./ThemAlloc";
import GeoAlloc from "./GeoAlloc";
import CurrencyAlloc from "./CurrencyAlloc";
import MarkCapAlloc from "./MarkCapAlloc";
import TermsDetails from "./TermsDetails";
import InLink from "../UI/InLink.vue";
import AnimateText from "../Animate/AnimateText.vue";
import FadeIn from "../Animate/FadeIn.vue";
import PageHeaderBackground from '@/components/UI/PageHeaderBackground';

export default {
  name: "GDN",
  components: {
    FeesGraph,
    PerfTable,
    ThemAlloc,
    GeoAlloc,
    CurrencyAlloc,
    MarkCapAlloc,
    TermsDetails,
    InLink,
    AnimateText,
    FadeIn,
    PageHeaderBackground,
  },
  computed: {
    getGDNData() {
      return this.$store.state.GDNData;
    },
    getThemAllocData() {
      return this.$store.state.themAllocData;
    },
    getGDNTerms() {
      return this.$store.state.GDNTerms;
    },
    getGDNPerfTableData() {
      return this.$store.state.GDNPerfTable;
    }
  },
  inject: ['locomotiveScroll'],
  data () {
    return {
      document: null,
    };
  },
  created () {
    this.getContent();
  },
  methods: {
    async getContent() {
      const document = await this.$prismic.client.getSingle('gdn_page');
      this.document = document.data;
      setTimeout(() => {
        const scroll = this.locomotiveScroll();
        scroll.start();
      }, 500);
    },
  },
};
</script>

<style lang="stylus">
.GDN {
  color: white;
  position: relative;
  z-index: 1;

  &_header {
    position: relative;
    width: 100vw;
    min-height: 100vh;
    padding: 20vh var(--respHorPadding) 0;
    margin-bottom: 10vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
      max-width: 45ch;
      font-size: 1.5em;
      line-height: 1.4;
      GradientText();

      @media (max-width: $breakMd) {
        font-size: 1.25em;
      }
    }

    .typeH2 {
      margin-right: auto;
    }

    .typeH2 div {
      GradientText();
    }
  }

  &_geoThem, &_themAlloc, &_markCap, &_currAlloc, &_feesGraph, &_perfTable {
    locoAppear();
  }

  &_TMS {
    margin-top: 150px;
  }
}

.GDN_row {
  display: flex;
  flex-direction: column;
  padding: 0 var(--respHorPadding);

  > div {
    padding: var(--respHorPadding) 0;
    margin-bottom: calc(var(--respHorPadding) * 2);
  }

  @media (min-width: $breakMd) {
    flex-direction: row;
    justify-content: space-between;

    > div:first-child {
      width: 60%;
      margin-bottom: 0;
    }

    > div:last-child {
      width: 36%;
      margin-bottom: 0;
    }
  }
}

.GDN_termsContainer {
  padding: 0 var(--respHorPadding);

  > * {
    padding: var(--respHorPadding) 0;
  }
}

.GDN_shortDisclaimer {
  margin: 0 auto;
  color: $blue;
  text-align: center;
  font-family: 'GTFlexa';
  font-weight: 200;

  @media (max-width: $breakSm) {
    font-size: 1rem; 
  }
}

.GDN_background {
  mix-blend-mode: lighten;

  &.-mobile {
    @media (min-width: $breakSm) {
      display: none;  
    }
  }

  &.-desktop {
    @media (max-width: $breakSm) {
      display: none;  
    }
  }
}

.GDN_backgroundGradient {
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  background-image: linear-gradient(to right, rgba($charcoal, 0.85), rgba($charcoal, 0) 75%);
}

.GDN_headerTitle {
  max-width: 70vw;
  margin-bottom: 0.7em;
}
</style>