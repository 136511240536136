<template>
  <img
    :srcset="srcset"
    :sizes="sizes"
    :src="image.url"
    :alt="alt || image.alt || ''"
    :width="image.dimensions.width"
    :height="image.dimensions.height"
    class="responsive-image"
  >
</template>
<script>
export default {
  props: {
    image: Object,
    alt: String,
    sizes: String,
  },
  computed: {
    srcset () {
      if (!this.image) return;
      const variants = Object.values(this.image).filter((v) => !!v?.url);
      return [this.image, ...variants].map((obj) => {
        return `${obj.url} ${obj.dimensions.width}w`;
      }).join(', ');
    }
  },
}
</script>
