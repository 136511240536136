<template>
  <div data-scroll-section class="ourTechBeliefs">
    <img
      data-scroll
      data-scroll-speed="-2"
      src="/assets/images/technology-algo-bg.jpg"
      alt=""
      draggable="false"
      class="ourTechBeliefs_background -desktop"
    >
    <img
      data-scroll
      data-scroll-speed="-2"
      src="/assets/images/technology-algo-bg-mobile.jpg"
      alt=""
      draggable="false"
      class="ourTechBeliefs_background -mobile"
    >
    <AnimateText
      data-scroll
      data-scroll-call="onAppearHeadingBeliefs, null"
      tag="h2"
      :appear="appearHeading"
      class="ourTechBeliefs_heading typeH2"
      v-text="title"
    />
    <div class="ourTechBeliefs_list">
      <div data-scroll data-scroll-offset="30%, 30%" v-for="(belief, i) in beliefs" :key="i" class="ourTechBeliefs_listItem">
        <span class="ourTechBeliefs_listItemNumber typeH2" v-text="`0${i + 1}`" />
        <p class="ourTechBeliefs_text typeGradient" v-text="$prismic.asText(belief.content)" />
      </div>
    </div>
  </div>
</template>

<script>
import AnimateText from '@/components/Animate/AnimateText';
export default {
  name: 'OurTechBeliefs',
  components: {
    AnimateText,
  },
  data () {
    return { 
      appearHeading: false,
    };
  },
  props: {
    title: String,
    beliefs: Array,
  },
  inject: ['locomotiveScroll'],
  mounted() {
    const scroll = this.locomotiveScroll();
    setTimeout(() => scroll.on('call', (e) => this.ourTechBeliefsHandler(e)), 100);
  },
  methods: {
    ourTechBeliefsHandler(caller) {
      let [ func, param ] = caller;
      if (this[func])
        this[func](param);
    },
    onAppearHeadingBeliefs() {
      this.appearHeading = true;
    },
  },
};
</script>

<style lang="stylus">
.ourTechBeliefs {
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 1;
  overflow: hidden;
  padding: 0 var(--respHorPadding) 12vh;

  @media (min-width: $breakMd) {
    padding: 0 var(--respHorPadding) 25vh;
  }

  &::before,
  &::after {
    position: absolute;
    content: "";
    left: 0;
    width: 100%;
    height: 15vh;
  }

  &::before {
    top: 0;
    background-image: linear-gradient(rgba(20, 19, 24, 1), rgba(20, 19, 24, 0));
  }

  &::after {
    bottom: 0;
    background-image: linear-gradient(rgba(20, 19, 24, 0), rgba(20, 19, 24, 1));
  }
}

.ourTechBeliefs_background {
  position: absolute;
  top: 10%; left: 0;
  z-index: -1;
  width: 100%; height: 100%;  
  object-fit: cover;

  &.-mobile {
    @media (min-width: $breakSm) {
      display: none;  
    }
  }

  &.-desktop {
    @media (max-width: $breakSm) {
      display: none;  
    }
  }
}

.ourTechBeliefs_heading {
  max-width: 70vw;
  margin: 0 auto 6vh;
  text-align: center;

  > div {
    GradientText();
  }

  @media (min-width: $breakMd) {
    max-width: none;
    margin: 0 auto 12vh;
  }
}

.ourTechBeliefs_list {
  display: flex;
  flex-direction: column;
}

.ourTechBeliefs_listItem {
  position: relative;
  margin-bottom: 4em;
  transform: translateY(5rem);
  transition: transform 1s cubic-bezier(0.16, 1, 0.3, 1);

  @media (min-width: $breakMd) {
    width: 47%;

    &:nth-child(even) {
      margin-left: auto;
    }
  }

  p {
    margin: 0.75em 1.5em 1.5em;
    opacity: 0;
    transition: opacity 0.5s ease 0.5s;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0; left: 0;
    width: 5em;
    height: 3px;
    background-color: $blue;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.75s cubic-bezier(0.16, 1, 0.3, 1) 0.5s;
  }
}

.ourTechBeliefs_listItemNumber {
  color: $blue;
  font-weight: 400;
  transform: rotateX(-90deg) scale(0.85) translateY(50%);
  transition: opacity 0.5s ease, transform 1.75s cubic-bezier(0.16, 1, 0.3, 1);
  opacity: 0;
}

.ourTechBeliefs_listItem.is-inview {
  transform: translateY(0);

  .ourTechBeliefs_listItemNumber {
    transform: rotateX(0deg) scale(1) translateY(0);
    opacity: 1;
  }

  .ourTechBeliefs_text {
    opacity: 1;
  }

  &::after {
    transform: scaleX(1);
  }
}

</style>