<template>
  <div>
    <transition name="disclaimer" mode="out-in">
      <DisclaimerForbidden v-if="disclaimerTMS.forbidden" key="forbidden" />
      <DisclaimerQuestion1 v-else-if="!disclaimerTMS.answered1" key="question" />
      <DisclaimerQuestion2 v-else-if="!disclaimerTMS.answered2" key="question" />
      <DisclaimerText v-else-if="!disclaimerTMS.accepted" key="text" />
      <TMS key="page" v-else />
    </transition>
  </div>
</template>

<script>
import DisclaimerQuestion1 from "@/components/TMS/Disclaimer/DisclaimerQuestion1.vue";
import DisclaimerQuestion2 from "@/components/TMS/Disclaimer/DisclaimerQuestion2.vue";
import DisclaimerText from "@/components/TMS/Disclaimer/DisclaimerText.vue";
import DisclaimerForbidden from "@/components/TMS/Disclaimer/DisclaimerForbidden.vue";
import TMS from "@/components/TMS/TMS.vue";

export default {
  name: "TMSPage",
  components: {
    DisclaimerQuestion1,
    DisclaimerQuestion2,
    DisclaimerText,
    DisclaimerForbidden,
    TMS,
  },
  computed: {
    disclaimerTMS () {
      return this.$store.state.disclaimerTMS;
    },
  },
};
</script>

<style scoped>
.disclaimer-enter-active,
.disclaimer-leave-active {
  transition: opacity 0.5s ease;
}

.disclaimer-enter,
.disclaimer-leave-to {
  opacity: 0;
}
</style>
