<template>
  <div v-if="document" class="team">
    <div data-scroll-section class="team_header">
      <div class="team_headerLeft">
        <div class="team_pageTitle">
          <AnimateText appear tag="h1" class="typeH1" v-text="$prismic.asText(document.title)" />
        </div>
        <FadeIn class="team_patternContainer" appear>
          <TeamPattern
            class="team_pattern -desktop"
            :active-index="activeIndex"
            @changeIndex="setActiveIndex"
            @openLinkedIn="openLinkedInTab"
          />
          <TeamPatternMobile
            class="team_pattern -mobile"
            :active-index="activeIndex"
            @changeIndex="setActiveIndex"
          />
        </FadeIn>
      </div>
      <div class="team_activeMember">
        <transition name="team-member" mode="out-in">
          <div class="team_activeMemberInner" :key="activeIndex">
            <a :href="members[activeIndex].linkedin_link.url" target="_blank" rel="noopener noreferrer">
              <AnimateText appear :delay="0.20" v-html="$prismic.asText(members[activeIndex].name)" class="team_activeMemberName typeH2" />
              <div class="team_activeMemberRole">
                <AnimateText appear :delay="0.35" v-html="$prismic.asText(members[activeIndex].role)" />
                <img v-if="members[activeIndex].linkedin_link.url" src="/assets/images/linkedin.png" alt="" class="team_activeMemberLinkedin" />
              </div>
            </a>
          </div>
        </transition>
      </div>
    </div>
    <div data-scroll-section class="team_cta">
      <InLink textCont="Contact Us" toLink="/contact" />
    </div>
  </div>
</template>

<script>
import InLink from "@/components/UI/InLink.vue";
import AnimateText from '@/components/Animate/AnimateText';
import FadeIn from '@/components/Animate/FadeIn';
import TeamPattern from '@/components/TeamPattern';
import TeamPatternMobile from '@/components/TeamPatternMobile';
export default {
  name: "Team",
  components: {
    InLink,
    AnimateText,
    FadeIn,
    TeamPattern,
    TeamPatternMobile,
  },
  data () {
    return {
      document: null,
      members: [],
      activeIndex: 7,
    };
  },
  inject: ['locomotiveScroll'],
  created () {
    this.getContent();
  },
  methods: {
    async getContent() {
      const document = await this.$prismic.client.getSingle('team_page');
      this.document = document.data;
      this.members = document.data.team_members;
      this.members.unshift({ name: '', role: '', linkedin_link: '' });
      setTimeout(() => {
        const scroll = this.locomotiveScroll();
        scroll.start();
      }, 500);
    },
    setActiveIndex (i) {
      this.activeIndex = i;
    },
    openLinkedInTab (i) {
      if (this.members[i].linkedin_link.url)
        window.open(this.members[i].linkedin_link.url,'_newtab');
    }
  }
};
</script>

<style lang="stylus">
.team_header {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  @media (max-width: $breakMd) {
    flex-direction: column;
    justify-content: space-between;
    padding: 15vh var(--respHorPadding) 0;
  }

  > * {
    position: relative;
    width: 100%;
  }
}

.team_headerLeft {
  @media (max-width: $breakMd) {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
  }
}

.team_pageTitle {
  display: flex;
  justify-content: center;
  margin-bottom: 4vh;

  @media (min-width: $breakMd) {
    position: absolute;
    top: 50%; left: 0; right: 40%;
    width: auto;
    transform: translateY(-50%);
  }

  .typeH1 {
    @media (max-width: $breakMd) { font-size: 2.5em; }
    @media (max-width: $breakSm) { font-size: 2em; }
  }
  
  div {
    GradientText();
  }
}

.team_patternContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5vw;
}

.team_pattern {
  width: 100%;

  &.-mobile {
    display: none;
    max-width: 60vw;
  }

  @media (max-width: $breakMd) {
    &.-desktop {
      display: none;
    }
      &.-mobile {
      display: block;
    }
  }
}

.team_cta {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 10vh;
}

.team_activeMember {
  flex: 2 1 auto;
  margin: auto 0;
}

.team_activeMemberInner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 0;

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (min-width: $breakMd) {
      align-items: flex-start;
    }
  }

  @media (min-width: $breakMd) {
    text-align: left;
    padding: 5vh var(--respHorPadding);
    height: auto;
  }
}

.team-member-enter-active,
.team-member-leave-active {
  transition: opacity 0.25s;
}

.team-member-enter,
.team-member-leave-to {
  opacity: 0;
}

.team_activeMemberName div {
  color: $blue;
}

.team_activeMemberRole {
  display: inline-flex;
  align-items: center;
  margin-top: 0.25em;
  font-family: 'Telegraph';
  font-weight: 200;
  font-size: 1.5em;

  @media (max-width: $breakMd) {
    font-size: 1em;
  }
}

.team_activeMemberLinkedin {
  display: inline-block;
  width: 1em;
  margin-left: 0.5em;
  opacity: 1;
  transition: opacity 0.75s ease 0.5s;
}

.team-member-enter-active .team_activeMemberLinkedin {
  opacity: 0;
}
</style>