<template>
  <div class="news-post">
    <transition name="fade" mode="out-in">
      <div v-if="document" key="post">
        <NewsPostHeader
          :title="$prismic.asText(document.title)"
          :category="document.category"
          :author="document.author"
          :date="document.date_published"
          :image="document.main_image"
        />
        <div v-if="document" class="news-post_content" data-scroll-section>
          <FadeIn data-scroll data-scroll-call="onAppearContent, null" :appear="appearContent">
            <prismic-rich-text :field="document.content" class="news-post_wysiwyg" />
          </FadeIn>
          <div v-if="document" class="news-post_footer">
            <SharingLinks
              :link="`https://taranis.net/news/${$route.params.uid}`"
              :title="$prismic.asText(document.title)"
            />
            <BackToTop />
          </div>
        </div>
        <div>
          <span class="news-post_related-heading">Related:</span>
          <NewsList v-if="newsPosts" :posts="newsPosts" />
        </div>
        <div data-scroll-section class="news-post_cta">
          <InLink textCont="Contact Us" toLink="/contact" />
        </div>
      </div>
      <div v-else style="height: 100vh;" key="loading" />
    </transition>
  </div>
</template>

<script>
import NewsPostHeader from "@/components/NewsPost/NewsPostHeader.vue";
import NewsList from "@/components/News/NewsList.vue";
import SharingLinks from "@/components/News/SharingLinks.vue";
import InLink from "@/components/UI/InLink.vue";
import BackToTop from "@/components/UI/BackToTop.vue";
import FadeIn from '@/components/Animate/FadeIn';
export default {
  name: "News",
  components: {
    NewsPostHeader,
    NewsList,
    SharingLinks,
    InLink,
    BackToTop,
    FadeIn,
  },
  inject: ['locomotiveScroll'],
  data () {
    return {
      document: null,
      newsPosts: null,
      appearContent: false,
    }
  },
  created () {
    this.getContent();
  },
  watch: {
    '$route' () {
      this.document = null;
      this.newsPosts = null;
      this.getContent();
    }
  },
  mounted() {
    const scroll = this.locomotiveScroll();
    setTimeout(() => scroll.on('call', (e) => this.newsPostHandler(e)), 1000);
  },
  methods: {
    async getContent() {
      const document = await this.$prismic.client.getByUID('news_post', this.$route.params.uid);
      const newsPosts = await this.$prismic.client.query(
        this.$prismic.Predicates.at('document.type','news_post'),
        {
          lang: 'en-us',
          orderings: '[my.news_post.date_published desc]',
          pageSize: 3,
        }
      );

      this.document = document.data;
      this.newsPosts = newsPosts.results.filter((item) => {
        return item.uid !== this.$route.params.uid;
      });

      setTimeout(() => {
        const scroll = this.locomotiveScroll();
        scroll.start();
      }, 500);
    },
    newsPostHandler(caller) {
      let [ func, param ] = caller;
      if (this[func])
        this[func](param);
    },
    onAppearContent () {
      this.appearContent = true;
    },
  }
};
</script>

<style lang="stylus">
.news-post {
  font-family: 'Telegraph';
  font-weight: 200;
}

.news-post_content {
  width: 100%;
  max-width: 100ch;
  margin: 0 auto 20vh;
  padding: var(--respHorPadding);

  @media (max-width: $breakMd) {
    margin: 0 auto 10vh;
  }
}

.news-post_wysiwyg {
  * {
    max-width: 100%;
  }

  p, ul, img {
    margin-bottom: 1.5em !important;
  }

  p, ul {
    font-size: 1.5rem;
    font-weight: 200;
    line-height: 1.5;

    @media (max-width: $breakMd) {
      font-size: 1.15em;
    }
  }

  strong {
    font-weight: 400;
  }

  ul li {
    list-style: disc outside;
    padding-left: 0.5em;
    margin: 0.25em 0 0.25em 1em;
  }

  h2, h3, h4, h5, h6 {
    display: inline-block;
    font-weight: 400;
    line-height: 1;
    font-family: 'GTFlexa';
    margin-bottom: 0.5em;
  }

  h2 {
    font-size: 2em;
    @media (max-width: $breakMd) { font-size: 1.75em; }
    @media (max-width: $breakSm) { font-size: 1.5em; }
  }

  h3 {
    font-size: 1.75em;
    @media (max-width: $breakMd) { font-size: 1.5em; }
    @media (max-width: $breakSm) { font-size: 1.25em; }
  }

  h4 {
    font-size: 1.5em;
    @media (max-width: $breakMd) { font-size: 1.25em; }
    @media (max-width: $breakSm) { font-size: 1em; }
  }

  h5, h6 {
    font-size: 1.25em;
    @media (max-width: $breakMd) { font-size: 1em; }
    @media (max-width: $breakSm) { font-size: 0.75em; }
  }
}

.news-post_footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10vh;

  @media (max-width: $breakMd) {
    margin-top: 5vh;
  }
}

.news-post_cta {
  width: 100%;
  display: flex;
  justify-content: center;
}

.news-post_related-heading {
  display: inline-block;
  font-size: 1.25rem;
  margin-bottom: 1em;
  margin-left: var(--respHorPadding);

  @media (max-width: $breakMd) {
    font-size: 1em;
  }
}
</style>