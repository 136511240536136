<template>
    <div data-scroll data-scroll-call="onMarkCapAllocAppear, null" class="markCapAlloc">
        <AnimateText tag="h2" :appear="inView" class="preData">
            Market Cap Allocation
        </AnimateText>
        <div v-if="getMarkCapData.length > 0" class="markCapAlloc_data">
            <FadeIn :appear="inView" class="markCapAlloc_data_list">
                <ul>
                    <li v-for="(data, i) in getMarkCapData" :key="i">
                        <div class="markCapAlloc_data_list_left">
                            {{ data.name }}
                        </div>
                        <div
                            class="markCapAlloc_data_list_bar"
                            :style="{ width: data.percentage * 6 + 'px' }"
                        ></div>
                        <div class="markCapAlloc_data_list_percent">
                            {{ data.percentage }} %
                        </div>
                    </li>
                </ul>
            </FadeIn>
        </div>
    </div>
</template>

<script>
import AnimateText from '@/components/Animate/AnimateText';
import FadeIn from '@/components/Animate/FadeIn';
export default {
    name: "MarkCapAlloc",
    components: {
        AnimateText,
        FadeIn,
    },
    computed: {
        getMarkCapData() {
            return this.$store.state.markCapData;
        },
    },
    data() {
        return {
        inView: false,
        };
    },
    inject: ['locomotiveScroll'],
    mounted() {
        const scroll = this.locomotiveScroll();
        setTimeout(() => scroll.on('call', (e) => this.markCapAllocHandler(e)), 100);
    },
    methods: {
        markCapAllocHandler(caller) {
            let [ func, param ] = caller;
            if (this[func])
                this[func](param);
        },
        onMarkCapAllocAppear() {
            this.inView = true;
        }
    },
};
</script>

<style lang="stylus" scoped>
.markCapAlloc {
    display: flex;
    flex-direction: column;
    align-items: center;

    &_data {
        display: flex;
        font-family: 'Telegraph';
        font-weight: 200;

        &_list {
            ul {
                li {
                    display: flex;
                    align-items: center; // <-- stretch vertical (default value, can be omitted here)
                }
            }

            &_left {
                color: $blue;
                padding: 40px 20px 20px 0;
                width: 200px;
                text-align: right;
                border-right: solid $blue 0.5px;

                @media (max-width: $breakSm) {
                    padding: 40px 10px 20px 0;
                }

                @media (max-width: $breakSm) {
                    width: 32vw;
                }
            }

            &_bar {
                height: 30px;
                max-width: 40%;
                background: $blue;
            }

            &_percent {
                padding-left: 20px;

                @media (max-width: $breakSm) {
                    padding-left: 10px;
                }
            }
        }
    }
}
</style>