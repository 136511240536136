<template>
  <component
    :is="tag"
    ref="text"
    class="fadeLink"
    :class="color"
    @mouseenter="animateIn"
    @mouseleave="animateOut"
  >
    <slot />
  </component>
</template>

<script>
import { gsap } from 'gsap';
import { SplitText } from '@/assets/js/gsap/SplitText';
gsap.registerPlugin(SplitText);

export default {
  name: 'FadeLink',
  props: {
    tag: {
      type: String,
      default: 'span',
    },
    duration: {
      type: Number,
      default: 0.25
    },
    delay: {
      type: Number,
      default: 0,
    },
    stagger: {
      type: Number,
      default: 0.03,
    },
    color: {
      type: String,
      default: 'blue',
      validator: function (value) {
        return ['light', 'white', 'blue'].indexOf(value) !== -1
      }
    }
  },
  data () {
    return {
      text: '',
      defaultColor: '#00a1c4',
      hoverColor: '#9fdce9',
    };
  },
  mounted () {
    if (this.color === 'light') {
      this.defaultColor = '#ffffff';
      this.hoverColor = '#00a1c4';
    } else if (this.color === 'white') {
      this.hoverColor = '#fff';
    }

    this.text = new SplitText(this.$refs.text, { type: 'chars', charsClass: 'char' });
  },
  methods: {
    animateIn () {
      const tl = gsap.timeline();
      tl.to(this.text.chars, { color: this.hoverColor, duration: this.duration, stagger: this.stagger, ease: 'linear' });
    },
    animateOut () {
      const tl = gsap.timeline();
      tl.to(this.text.chars, { color: this.defaultColor, duration: this.duration, stagger: this.stagger, ease: 'linear' });
    },
  },
};
</script>

<style lang="stylus">
.fadeLink {
  &.blue .char {
    color: $blue;
  }
  &.white .char {
    color: $blue;
  }
  &.light .char {
    color: white;
  }
}
</style>
