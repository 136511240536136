import { render, staticRenderFns } from "./TMSPage.vue?vue&type=template&id=2ddfed44&scoped=true&"
import script from "./TMSPage.vue?vue&type=script&lang=js&"
export * from "./TMSPage.vue?vue&type=script&lang=js&"
import style0 from "./TMSPage.vue?vue&type=style&index=0&id=2ddfed44&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ddfed44",
  null
  
)

export default component.exports