<template>
  <div class="news">
    <NewsHeader title="News" />
    <transition name="fade" mode="out-in">
      <NewsList v-if="newsPosts" :posts="newsPosts" class="news_list" key="news" />
      <div v-else style="height: 100vh;" key="loading" />
    </transition>
    <div data-scroll-section class="news_cta">
      <InLink textCont="Contact Us" toLink="/contact" />
    </div>
  </div>
</template>

<script>
import NewsHeader from "@/components/News/NewsHeader.vue";
import NewsList from "@/components/News/NewsList.vue";
import InLink from "@/components/UI/InLink.vue";
export default {
  name: "News",
  components: {
    NewsHeader,
    NewsList,
    InLink,
  },
  data () {
    return {
      newsPosts: null,
    }
  },
  created () {
    this.getContent();
  },
  methods: {
    async getContent() {
      const newsPosts = await this.$prismic.client.query(
        this.$prismic.Predicates.at('document.type', 'news_post'),
        {
          lang: 'en-us',
          pageSize: 100,
          orderings: '[my.news_post.date_published desc]',
        },
      );
      this.newsPosts = newsPosts.results;
    },
  }
};
</script>

<style lang="stylus">
.news {
  font-family: 'Telegraph';
  font-weight: 200;

  .typeH2 div {
    GradientText();
  }
}

.news_cta {
  width: 100%;
  display: flex;
  justify-content: center;
}

.news_list {
  min-height: 50vh;
}
</style>