<template>
  <component
    :is="tag"
    ref="el"
    class="animate-fade-in"
  >
  <slot />
  </component>
</template>

<script>
import { gsap } from 'gsap';
export default {
  name: 'AnimateFadeIn',
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    duration: {
      type: Number,
      default: 1
    },
    delay: {
      type: Number,
      default: 0.25
    },
    appear: Boolean,
  },
  computed: {
    readyToAnimate () {
      return this.appear;
    }
  },
  watch: {
    appear (newAppear) {
    if (newAppear)
      gsap.delayedCall(this.delay, this.animate);
  }
  },
  mounted () {
    if (this.readyToAnimate)
      gsap.delayedCall(this.delay, this.animate);
  },
  methods: {
    animate () {
      gsap.to(this.$refs.el, { opacity: 1, duration: this.duration })
    }
  },
};
</script>

<style lang="stylus">
.animate-fade-in {
  opacity: 0;
}
</style>
