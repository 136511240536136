<template>
  <button
    type="button"
    aria-controls="mobile-nav"
    :aria-expanded="mobileNavOpen ? 'true' : 'false'"
    class="mobile-nav-toggle"
    @click="toggleMobileNav"
  >
    <span class="mobileNavToggle_sr-only" v-text="mobileNavOpen ? 'Close navigation' : 'Open navigation'" />
    <span class="hamburger" :class="{ '-open' : mobileNavOpen }"><i /><i /><i /></span>
  </button>
</template>

<script>
import { state, mutations } from './MobileNavState';

export default {
  name: 'MobileNavToggle',
  computed: {
    mobileNavOpen () { return state.mobileNavOpen }
  },
  methods: {
    toggleMobileNav () { mutations.toggleMobileNav() }
  },
}
</script>

<style lang="stylus">
.mobile-nav-toggle {
  display: flex;
  flex-direction: column;

  width: auto;
  padding: 10px;
  margin-right: -10px;
  border: none;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  cursor: pointer;

  &:focus {
    outline: 0;
  }

  @media (min-width: $breakMd) {
    display: none;
  }
}

.hamburger i {
  display: block;
  width: 20px;
  height: 2px;
  margin: 4px 0;
  background-color: $blue;
  transition: transform 0.5s cubic-bezier(0.76, 0, 0.24, 1);

  &:first-child {
    width: 12px;
    margin-right: auto;
  }

  &:last-child {
    width: 12px;
    margin-left: auto;
  }
}

.hamburger.-open i {
  &:first-child { transform: translateX(8px); }
  &:last-child { transform: translateX(-8px); }
}

.mobileNavToggle_sr-only {
  position: absolute;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  white-space: nowrap;
}
</style>
