<template>
  <footer class="siteFooter" data-scroll-section>
    <div class="siteFooter_row">
      <div>
        Taranis
      </div>
      <div>
        <a href="https://goo.gl/maps/aVCT8PqtRvdwQ1Ru9" target="_blank" rel="noopener noreferrer">
          <FadeLink>
            Rue du Marché 7<br>
            1204 Geneva<br>
            Switzerland
          </FadeLink>
        </a>
      </div>
      <ul>
        <li><a href="tel:+41223184390"><FadeLink>+41 22 318 43 90</FadeLink></a></li>
        <li><a href="mailto:investments@taranis.net"><FadeLink>investments@taranis.net</FadeLink></a></li>
        <li><a href="https://www.linkedin.com/company/taranis-sa/" target="_blank" rel="noopener noreferrer"><FadeLink>LinkedIn</FadeLink></a></li>
      </ul>
    </div>
    <p>
      (c) {{ currentYear }} Taranis. All right reserved.
    </p>
    <img src="/assets/images/PATTERNFooter.png" alt="" />
  </footer>
</template>

<script>
import FadeLink from "../components/Animate/FadeLink.vue";
export default {
  name: "MainFooter",
  components: {
    FadeLink,
  },
  computed: {
    currentYear () { return new Date().getFullYear() }
  }
};
</script>

<style lang="stylus" scoped>
.siteFooter {
  position: relative;
  z-index: 2;
  padding: 200px 0 50px $horPadding;

  @media (max-width: $breakSm) {
    padding: 150px var(--respHorPadding) var(--respHorPadding);
  }

  p {
    font-size: 1.1em;

    @media (max-width: $breakSm) {
      font-size: 0.825em;
    }
  }

  img {
    position: absolute;
    bottom: 0;
    width: 40vw;
    height: auto;
    pointer-events: none;
    right: 0;
    z-index: 0;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;

    @media (max-width: $breakSm) {
      display: none;
    }
  }
}

.siteFooter_row {
  display: flex;
  justify-content: flex-start;

  > * {
    flex: 0 0 auto;
    color: $blue;
    font-family: "Telegraph";
    font-size: 1.1em;
    font-weight 200;
    line-height: 1.4;
    margin: 0 4em 4em 0;

    @media (max-width: $breakSm) {
      font-size: 0.825em;
      margin: 0 3em 3em 0;
    }

    a {
      color: $blue;
    }
  }
}
</style>