<template>
  <div v-if="document" class="ourTechnology">
    <OurTechHeader
      :header-before="document.rotating_header_before"
      :header-after="document.rotating_header_after"
      :text-variants="document.rotating_header_words"
    />
    <div data-scroll-section class="ourTechnology_text">
      <AnimateText data-scroll data-scroll-call="onAppearP1, null" tag="p" :appear="appearP1" v-text="$prismic.asText(document.lead_1)" />
      <br /><br />
      <AnimateText data-scroll data-scroll-call="onAppearP2, null" tag="p" :appear="appearP2" v-text="$prismic.asText(document.lead_2)" />
    </div>
    <OurTechApproach :title="$prismic.asText(document.our_approach_heading)" :content="document.our_approach_content" />
    <OurTechBeliefs
      :title="$prismic.asText(document.technology_list_heading)"
      :beliefs="document.technology_list"
    />
    <div data-scroll-section class="ourTechnology_invest">
      <InLink textCont="Our Investment Solutions" toLink="/investment" />
    </div>
  </div>
</template>

<script>
import OurTechHeader from "../components/ourTechnology/OurTechHeader.vue";
import OurTechApproach from "../components/ourTechnology/OurTechApproach.vue";
import OurTechBeliefs from "../components/ourTechnology/OurTechBeliefs.vue";
import InLink from "../components/UI/InLink.vue";
import AnimateText from '@/components/Animate/AnimateText';
export default {
  name: "OurTechnology",
  components: {
    OurTechHeader,
    OurTechApproach,
    OurTechBeliefs,
    InLink,
    AnimateText,
  },
  data() {
    return {
      document: null,
      appearP1: false,
      appearP2: false,
    };
  },
  inject: ['locomotiveScroll'],
  created () {
    this.getContent();
  },
  mounted() {
    const scroll = this.locomotiveScroll();
    setTimeout(() => scroll.on('call', (e) => this.ourTechHandler(e)), 100);
  },
  methods: {
    async getContent() {
      const document = await this.$prismic.client.getSingle('our_technology_page');
      this.document = document.data;
      setTimeout(() => {
        const scroll = this.locomotiveScroll();
        scroll.start();
      }, 500);
    },
    ourTechHandler(caller) {
      let [ func, param ] = caller;
      if (this[func])
        this[func](param);
    },
    onAppearP1 () {
      this.appearP1 = true;
    },
    onAppearP2 () {
      this.appearP2 = true;
    },
  },
};
</script>

<style lang="stylus">
.ourTechnology {
  font-family: 'Telegraph';
  font-weight: 200;

  &_text {
    position: relative;
    margin: 5vh 12vw;

    p > div {
      GradientText();
    }

    @media (max-width: $breakSm) {
      max-width: 38ch;
      margin: 0;
      padding: 0 var(--respHorPadding);
    }

    p {
      max-width: 40ch;
    }
  }

  &_invest {
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 4;
    position: relative;
  }
}
</style>