<template>
  <div class="doughnutGraph">
    <div class="doughnutGraph_canvas">
      <canvas ref="themAllocCanvas"></canvas>
    </div>
    <div>
      <ul li class="doughnutGraph_labels">
        <li v-for="(dat, i) in data" :key="i" class="doughnutGraph_labels_label">
          <div :style="{ background: dat.color }" class="doughnutGraph_labels_label_colTag"></div>
          {{ dat.name }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";

export default {
  name: "DoughnutGraph",
  props: {
    data: []
  },
  mounted() {
    const rawPerc = [];
    const rawCol = [];
    this.data.forEach(rec => {
      rawPerc.push(rec.percentage);
      rawCol.push(rec.color);
    });
    const myChart = new Chart(this.$refs.themAllocCanvas.getContext("2d"), {
      type: "doughnut",
      data: {
        datasets: [
          {
            label: "My First Dataset",
            data: rawPerc,
            backgroundColor: rawCol,
            hoverOffset: 4
          }
        ]
      },
      options: {
        elements: {
          arc: {
            borderWidth: 0
          }
        }
      }
    });
  }
};
</script>

<style lang="stylus" scoped>
.doughnutGraph {
  font-family: 'Telegraph';
  display: flex;

  &_canvas {
    width: 17vw;
    height: auto;

    @media (max-width: $breakSm) {
      width: 55vw;
    }
  }

  &_labels {
    padding-left: 50px;

    &_label {
      display: flex;
      align-items: center;
      padding: 10px 0;

      &_colTag {
        width: 10px;
        height: 10px;
        border-radius: 100%;
        margin-right: 10px;
      }
    }
  }
}
</style>