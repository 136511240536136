<template>
    <div data-scroll data-scroll-call="onGeoAllocAppear, null" class="geoAlloc">
        <div class="geoAlloc_header">
            <AnimateText tag="h2" :appear="inView" class="preData">
                Geographical Allocation
            </AnimateText>
        </div>
        <FadeIn :appear="inView" class="geoAlloc_map">
            <img
                class="geoAlloc_map_image"
                :src="mapImage.url"
                alt="world map"
            />
            <ul class="geoAlloc_map_list" v-if="getGeoAlloc.length > 0">
                <li
                    class="geoAlloc_map_list_item"
                    v-for="(data, i) in getGeoAlloc"
                    :key="i"
                    :style="{ top: data.yPos + '%', left: data.xPos + '%' }"
                >
                    {{ data.percentage }} %
                </li>
                <!-- <li
          class="geoAlloc_map_list_item"
          v-for="(data, i) in getGeoAlloc"
          :key="i"
        >{{data.percentage}}</li>-->
            </ul>
        </FadeIn>
    </div>
</template>

<script>
import AnimateText from "@/components/Animate/AnimateText";
import FadeIn from "@/components/Animate/FadeIn";
export default {
    name: "GeoAlloc",
    components: {
        AnimateText,
        FadeIn,
    },
    props: {
        mapImage: {
            type: Object,
            default: '/assets/images/worldmap.svg',
        },
    },
    computed: {
        getGeoAlloc() {
            return this.$store.state.geoAllocData;
        },
    },
    data() {
        return {
            inView: false,
        };
    },
    inject: ["locomotiveScroll"],
    mounted() {
        const scroll = this.locomotiveScroll();
        setTimeout(
            () => scroll.on("call", (e) => this.geoAllocHandler(e)),
            100
        );
    },
    methods: {
        geoAllocHandler(caller) {
            let [func, param] = caller;
            if (this[func]) this[func](param);
        },
        onGeoAllocAppear() {
            this.inView = true;
        },
    },
};
</script>

<style lang="stylus" scoped>
.geoAlloc {
    &_map {
        width: 75%;
        position: relative;

        @media (max-width: $breakMd) {
            width: 100%;
        }

        &_list {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            &_item {
                font-family: 'Telegraph';
                position: absolute;
                font-size: "min(1em, 2.5vw)" % null;
            }
        }
    }
}

.geoAlloc_header {
    display: flex;
    justify-content: center;
    width: 75%;

    @media (max-width: $breakMd) {
        width: 100%;
    }
}
</style>