<template>
  <transition name="mobileNav">
    <nav
      v-show="mobileNavOpen"
      id="mobileNav"
      class="mobileNav"
      :aria-hidden="mobileNavOpen ? 'false' : 'true'"
    >
      <img src="/assets/images/PATTERNFooter.svg" alt="" class="mobileNav_pattern">
      <div class="mobileNav_content">
        <ul class="mobileNav_list">
          <li v-for="(link, i) in links" :key="i" class="mobileNav_item">
            <MobileNavLink :link="link" :index="i" />
          </li>
        </ul>
      </div>
    </nav>
  </transition>
</template>

<script>
import { state, mutations } from './MobileNavState';
import MobileNavLink from './MobileNavLink.vue';

export default {
  name: 'MobileNav',
  components: {
    MobileNavLink,
  },
  computed: {
    links () { return state.links },
    mobileNavOpen () { return state.mobileNavOpen },
  },
  watch: {
    $route (newRoute) {
      setTimeout(this.closeMobileNav, 250);
    },
  },
  methods: {
    closeMobileNav () { mutations.closeMobileNav() },
  },
}
</script>

<style lang="stylus">
.mobileNav {
  position: fixed;
  top: 0; right: 0; bottom: 0; left: 0;
  zindex('MobileNav');
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: $charcoal;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  &::-webkit-scrollbar { display: none; }

  @media (min-width: $breakMd) {
    display: none;
  }
}

.mobileNav_content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  overflow-x: hidden;
  width: 100%;
  min-height: 100%;
  margin: 0 auto;
}

.mobileNav_list {
  margin: auto 0;
  padding: calc(var(--respHorPadding) * 3) var(--respHorPadding) calc(var(--respHorPadding) * 6);
}

.mobileNav_pattern {
  position: absolute;
  bottom: 0; left: 0;
  width: 135%;
  opacity: 0.5;

  @media (min-aspect-ratio: 3/4) {
    width: 100%;
    right: 0;
    left: auto;
    margin-left: auto;
  }

  @media (min-aspect-ratio: 1/1) {
    width: 70%;
  }
}

//
// Open/Close transition
//

.mobileNav-enter-active,
.mobileNav-leave-active {
  transition: opacity 0.5s ease;
}

.mobileNav-leave-active {
  transition: opacity 0.75s ease 0.25s;
}

.mobileNav-enter,
.mobileNav-leave-active {
  opacity: 0;
}
</style>
