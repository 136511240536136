<template>
<div>
  <div data-scroll-section class="contact">
    <PageHeaderBackground bottom-gradient>
      <video src="/assets/videos/contact-bg.mp4" type="video/mp4" class="contact_background -desktop" playsinline loop muted autoplay />
      <video src="/assets/videos/contact-bg-mobile.mp4" type="video/mp4" class="contact_background -mobile" playsinline loop muted autoplay />
    </PageHeaderBackground>
    <div class="contact_details">
      <AnimateText tag="h1" appear :delay="0.5" class="typeH3">
        <span class="typeGradient">Taranis</span>
      </AnimateText>
      <FadeIn appear :delay="1">
        <p class="typeGradient">
          Rue du Marché 7<br>
          1204 Geneva<br>
          Switzerland
        </p>
      <ul>
        <li><a href="tel:+41223184390">
          <FadeLink>+41 22 318 43 90</FadeLink>
        </a></li>
        <li><a href="mailto:investments@taranis.net">
          <FadeLink>investments@taranis.net</FadeLink>
        </a></li>
        <li><a href="https://www.linkedin.com/company/taranis-sa/" target="_blank" rel="noopener noreferrer" class="contact_linkedin">
          <FadeLink>LinkedIn</FadeLink>
          <img src="/assets/images/linkedin.png" alt="" class="contact_linkedinLogo" />
        </a></li>
      </ul>
      </FadeIn>
    </div>
    <div class="contact_form">
      <AnimateText tag="h2" appear :delay="0.75" class="typeH3">
        <span class="typeGradient">Contact us</span>
      </AnimateText>
      <FadeIn appear :delay="1">
      <ContactForm />
      </FadeIn>
    </div>
  </div>
  <div data-scroll-section class="contact_cta">
    <InLink textCont="Our Team" toLink="/team" />
  </div>
</div>
</template>

<script>
import PageHeaderBackground from '@/components/UI/PageHeaderBackground';
import AnimateText from "../components/Animate/AnimateText.vue";
import FadeIn from "../components/Animate/FadeIn.vue";
import FadeLink from "../components/Animate/FadeLink.vue";
import ContactForm from "../components/contact/ContactForm.vue";
import InLink from "@/components/UI/InLink.vue";
export default {
  name: "OurTechnology",
  components: {
    PageHeaderBackground,
    AnimateText,
    FadeIn,
    FadeLink,
    ContactForm,
    InLink
  }
};
</script>

<style lang="stylus" scoped>
.contact {
  position: relative;
  display: flex;
  align-items: flex-start;
  padding: 25vh var(--respHorPadding) 15vh;
  overflow: hidden;

  @media (max-width: $breakSm) {
    flex-direction: column;
    padding-top: calc(var(--respHorPadding) * 2 + 15vh);
    padding-bottom: 10vh;
  }
}

.contact_background {
  mix-blend-mode: lighten;

  &.-mobile {
    @media (min-width: $breakSm) {
      display: none;  
    }
  }

  &.-desktop {
    @media (max-width: $breakSm) {
      display: none;  
    }
  }
}

.contact_details,
.contact_form {
  position: relative;

  @media (max-width: $breakSm) {
    width: 100% !important;
    margin-bottom: 7vh;
  }
}

.contact_details {
  width: 33%;

  .typeH3 { margin-bottom: 1.3em; }
  p { margin-bottom: 2em; }
  li { margin-bottom: 1em; }

  a {
    color: $blue;
    font-family: "Telegraph";
    font-size: 1.2em;
    font-weight 200;
  }
}

.contact_form {
  margin: 0 auto;
  width: 50%;
}


.contact_linkedin {
  display: inline-flex;
  align-items: center;

  .contact_linkedinLogo {
    width: 1em;
    margin-left: 0.5em;
    margin-bottom: 0.15em;
  }
}

.contact_cta {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>