<template>
  <a href="#main" class="back-to-top" @click.prevent="backToTop">
    <span>Back to top</span>
    <img src="/assets/images/arrow-up.png" alt="">
  </a>
</template>

<script>
export default {
  name: "BackToTop",
  inject: ["locomotiveScroll"],
  methods: {
    backToTop () {
      const scroll = this.locomotiveScroll();
      scroll.scrollTo('top');
    }
  },
};
</script>

<style lang="stylus">
.back-to-top {
  display: flex;
  align-items: center;
  transition: opacity 0.25s ease;

  &:hover {
    opacity: 0.75;
  }

  > * {
    font-size: 1.15rem;
    color: $blue;
  }

  img {
    width: 0.85em;
    margin-left: 0.75em;
    image-rendering: crisp-edges;
  }
}
</style>