<template>
  <div class="pageHeaderBackground" :class="{ '-has-bottom-gradient' : bottomGradient }">
    <div class="pageHeaderBackground_inner" data-scroll data-scroll-position="top" :data-scroll-speed="scrollSpeed">
    <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "PageHeaderBackground",
  props: {
    bottomGradient: Boolean,
    scrollSpeed: {
      type: Number,
      default: -2,
    },
  },
};
</script>

<style lang="stylus">
.pageHeaderBackground {
  position: absolute;
  z-index: -1;
  top: 0; left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &_inner {
    width: 100%;
    height: 100%;
    background-color: $charcoal;
  }

  img, video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &.-has-bottom-gradient {
    &::after {
      content: "";
      position: absolute;
      right: 0; bottom: -2px; left: 0;
      height: 33%;
      background-image: linear-gradient(rgba($charcoal, 0), rgba($charcoal, 1) 85%);
    }
  }
}
</style>
