import Vue from 'vue'
import Vuex from 'vuex'

var Airtable = require('airtable');
var base = new Airtable({ apiKey: 'keyrcJoFqHsp5Oo6Y' }).base('appYr66kCZeLJFPwt');

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        disclaimerGDN: {
            answered: false,
            accepted: false,
            forbidden: false,
        },
        disclaimerTMS: {
            answered1: false,
            answered2: false,
            accepted: false,
            forbidden: false,
        },
        GDNData: [],
        TMSData: [],
        themAllocData: [],
        currencyAllocData: [],
        markCapData: [],
        GDNPerfTable: [],
        TMSPerfTable: [],
        geoAllocData: [],
        GDNTerms: [],
        TMSTerms: [],
    },
    mutations: {
        setDisclaimerGDN(state, payload) {
            state.disclaimerGDN = payload;
        },
        setDisclaimerTMS(state, payload) {
            state.disclaimerTMS = payload;
        },
        onLoadGDNData(state, data) {
            state.GDNData = data
        },
        onLoadTMSData(state, data) {
            state.TMSData = data
        },
        onLoadThemAllocData(state, data) {
            state.themAllocData = data
        },
        onLoadCurrencyAllocData(state, data) {
            state.currencyAllocData = data
        },
        onLoadMarkCapData(state, data) {
            state.markCapData = data
        },
        onLoadGDNPerfTable(state, data) {
            state.GDNPerfTable = data
        },
        onLoadTMSPerfTable(state, data) {
            state.TMSPerfTable = data
        },
        onLoadGeoAlloc(state, data) {
            state.geoAllocData = data
        },
        onLoadGDNTerms(state, data) {
            state.GDNTerms = data
        },
        onLoadTMSTerms(state, data) {
            state.TMSTerms = data
        },
    },
    actions: {
        loadGDNData(context) {
            let data = []
            base('GDN-Data').select({
                view: "Grid view"
            }).eachPage(function page(records, fetchNextPage) {
                console.log(records.length)
                records.forEach(function (record) {
                    data.push(record.fields)
                });
                context.commit('onLoadGDNData', data)
                fetchNextPage();

            }, function done(err) {
                if (err) { console.error(err); return; }
            });
        },
        loadTMSData(context) {
            let data = []
            base('TMS-Data').select({
                view: "Grid view"
            }).eachPage(function page(records, fetchNextPage) {
                records.forEach(function (record) {
                    data.push(record.fields)
                });
                context.commit('onLoadTMSData', data)

            }, function done(err) {
                if (err) { console.error(err); return; }
            });
        },
        loadThemAllocData(context) {
            base('thematicAllocation').select({
                view: "Grid view"
            }).eachPage(function page(records, fetchNextPage) {
                let data = []
                records.forEach(function (record) {
                    data.push(record.fields)
                });
                context.commit('onLoadThemAllocData', data)
                fetchNextPage();

            }, function done(err) {
                if (err) { console.error(err); return; }
            });
        },
        loadCurrencyAllocData(context) {
            base('currencyAllocation').select({
                view: "Grid view"
            }).eachPage(function page(records, fetchNextPage) {
                let data = []
                records.forEach(function (record) {
                    data.push(record.fields)
                });
                context.commit('onLoadCurrencyAllocData', data)
                fetchNextPage();

            }, function done(err) {
                if (err) { console.error(err); return; }
            });
        },
        loadMarkCapData(context) {
            base('marketCapAllocation').select({
                view: "Grid view"
            }).eachPage(function page(records, fetchNextPage) {
                let data = []
                records.forEach(function (record) {
                    data.push(record.fields)
                });
                context.commit('onLoadMarkCapData', data)
                fetchNextPage();

            }, function done(err) {
                if (err) { console.error(err); return; }
            });
        },
        loadGDNPerformanceTable(context) {
            base('GDN-PerformanceTable').select({
                view: "Grid view"
            }).eachPage(function page(records, fetchNextPage) {
                let data = []
                records.forEach(function (record) {
                    data.push(record.fields)
                });
                context.commit('onLoadGDNPerfTable', data)
                fetchNextPage();

            }, function done(err) {
                if (err) { console.error(err); return; }
            });
        },
        loadTMSPerformanceTable(context) {
            base('TMS-PerformanceTable').select({
                view: "Grid view"
            }).eachPage(function page(records, fetchNextPage) {
                let data = []
                records.forEach(function (record) {
                    data.push(record.fields)
                });
                context.commit('onLoadTMSPerfTable', data)
                fetchNextPage();

            }, function done(err) {
                if (err) { console.error(err); return; }
            });
        },
        loadGeographicalAllocation(context) {
            base('geographicalAllocation').select({
                view: "Grid view"
            }).eachPage(function page(records, fetchNextPage) {
                let data = []
                records.forEach(function (record) {
                    data.push(record.fields)
                });
                context.commit('onLoadGeoAlloc', data)
                fetchNextPage();

            }, function done(err) {
                if (err) { console.error(err); return; }
            });
        },
        loadGDNTerms(context) {
            base('GDN-Terms').select({
                view: "Grid view"
            }).eachPage(function page(records, fetchNextPage) {
                let data = []
                records.forEach(function (record) {
                    data.push(record.fields)
                });
                context.commit('onLoadGDNTerms', data)
                fetchNextPage();

            }, function done(err) {
                if (err) { console.error(err); return; }
            });
        },
        loadTMSTerms(context) {
            base('TMS-Terms').select({
                view: "Grid view"
            }).eachPage(function page(records, fetchNextPage) {
                let data = []
                records.forEach(function (record) {
                    data.push(record.fields)
                });
                context.commit('onLoadTMSTerms', data)
                fetchNextPage();

            }, function done(err) {
                if (err) { console.error(err); return; }
            });
        }
    },
})
