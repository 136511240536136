<template>
    <div
        data-scroll
        data-scroll-call="onFeesGraphAppear, null"
        class="feesGraph"
    >
        <AnimateText tag="h2" :appear="inView" class="preData">
            Performance Graph Evolution – Net of Fees
        </AnimateText>
        <FadeIn :appear="inView" class="feesGraph_canvasContainer">
            <canvas ref="chartCanvas" class="feesGraph_canvas" />
        </FadeIn>
    </div>
</template>

<script>
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import AnimateText from "@/components/Animate/AnimateText";
import FadeIn from "@/components/Animate/FadeIn";
export default {
    name: "FeesGraph",
    props: {
        data: null,
        formatDates: Boolean,
    },
    components: {
        AnimateText,
        FadeIn,
    },
    data() {
        return {
            inView: false,
        };
    },
    inject: ["locomotiveScroll"],
    mounted() {
        const scroll = this.locomotiveScroll();
        setTimeout(
            () => scroll.on("call", (e) => this.feesGraphHandler(e)),
            100
        );

        let rawData = [];
        let rawDates = [];

        this.data.forEach((num, i) => {
            rawData.push(num.value);
            if (this.formatDates) {
                const parsedDate = new Date(Date.parse(num.date));
                const formattedDate = new Intl.DateTimeFormat('en-GB', {
                    year: "2-digit",
                    month: "short",
                }).format(parsedDate);
                const formattedDateWithApostrophe = formattedDate.replace(' ', ' ’');
                if (i === 0) {
                    rawDates.push('28th ' + formattedDateWithApostrophe);
                } else {
                    rawDates.push(formattedDateWithApostrophe);
                }
            } else {
                rawDates.push(num.date);
            }
        });

        let myChart = new Chart(this.$refs.chartCanvas.getContext("2d"), {
            type: "line",
            plugins: [ChartDataLabels],
            data: {
                labels: rawDates,
                datalabels: {
                    color: "#36A2EB",
                },
                datasets: [
                    {
                        label: "",
                        data: rawData,
                        borderColor: "rgb(75, 192, 192)",
                        tension: 0,
                    },
                ],
            },
            options: {
                layout: {
                    padding: {
                        top: 25,
                        right: 15,
                    }
                },
                plugins: {
                    datalabels: {
                        anchor: "center",
                        align: "top",
                        offset: 5,
                        textAlign: 'right',
                        color: "white",
                        font: {
                            size: 15,
                            family: "Telegraph",
                        },
                        display: function (context) {
                            return context.dataIndex === rawData.length - 1;
                        },
                    },
                    legend: {
                        display: false,
                    },
                },

                scales: {
                    y: {
                        // not 'yAxes: [{' anymore (not an array anymore)
                        ticks: {
                            color: "#00a1c4", // not 'fontColor:' anymore
                            // fontSize: 18,
                            font: {
                                size: 10, // 'size' now within object 'font {}'
                                family: "Telegraph",
                            },
                            stepSize: 1,
                            beginAtZero: true,
                        },
                    },
                    x: {
                        // not 'xAxes: [{' anymore (not an array anymore)
                        ticks: {
                            color: "#00a1c4", // not 'fontColor:' anymore
                            //fontSize: 14,
                            font: {
                                family: "Telegraph",
                                size: 10, // 'size' now within object 'font {}'
                            },
                            stepSize: 1,
                            beginAtZero: true,
                        },
                    },
                },

                tooltips: {
                    enabled: false,
                },
                elements: {
                    point: {
                        radius: 0,
                    },
                },
            },
        });
    },
    watch: {
        data(oldData, newData) {},
    },
    methods: {
        feesGraphHandler(caller) {
            let [func, param] = caller;
            if (this[func]) this[func](param);
        },
        onFeesGraphAppear() {
            this.inView = true;
        },
    },
};
</script>

<style lang="stylus" scoped>
.feesGraph {
    display: flex;
    flex-direction: column;
    align-items: center;

    .feesGraph_canvasContainer {
        width: 100%;
    }
}
</style>