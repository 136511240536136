<template>
  <div class="navLink" ref="navLink">
    <router-link :to="routerLink">
      {{ textCont }}
      <div class="underLine" ref="underLine"></div>
    </router-link>
    <DropDown v-if="hasDL != false" :dropLinks="dropLinks" :activate="isActive" />
  </div>
</template>

<script>
import DropDown from "./DropDown.vue";

export default {
  name: "NavLink",
  data() {
    return {
      isActive: false
    };
  },
  components: {
    DropDown
  },
  props: {
    hasDL: true,
    textCont: String,
    routerLink: String,
    dropLinks: Array
  },

  mounted() {
    this.$refs.navLink.addEventListener("mouseenter", this.onEnter);
    this.$refs.navLink.addEventListener("mouseleave", this.onLeave);
  },
  methods: {
    onEnter() {
      this.isActive = true;
      this.$refs.underLine.classList.add("in");
      this.$refs.underLine.classList.remove("out");
    },
    onLeave() {
      this.isActive = false;
      this.$refs.underLine.classList.remove("in");
      this.$refs.underLine.classList.add("out");
    }
  }
};
</script>

<style lang="stylus" scoped>
.navLink {
  position: relative;
  cursor: pointer;
  padding-right: var(--respHorPadding);

  a {
    position: relative;

    .underLine {
      content: '';
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 100%;
      height: 1px;
      taraGradient();
      background-size: 150% 150%;
      -webkit-animation: gradientAnimation 1s ease infinite;
      -moz-animation: gradientAnimation 1s ease infinite;
      animation: gradientAnimation 1s ease infinite;
      transition: transform 0.3s;
      transform: scaleX(0);
      transform-origin: bottom left;

      &.in {
        transform: scaleX(1);
      }

      &.out {
        transform-origin: bottom right;
        transform: scaleX(0);
      }
    }
  }

  @keyframes gradientAnimation {
    0% {
      background-position: 0% 50%;
    }

    50% {
      background-position: 100% 50%;
    }

    100% {
      background-position: 0% 50%;
    }
  }
}
</style>