<template>
  <div class="dropDown">
    <ul ref="dropLinks">
      <li v-for="(dropLink, i) in dropLinks" :key="i">
        <router-link :to="dropLink.link"><FadeLink color="white" v-text="dropLink.text" /></router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import FadeLink from "@/components/Animate/FadeLink.vue";
export default {
  name: "DropDown",
  props: {
    dropLinks: Array,
    activate: Boolean
  },
  components: {
    FadeLink
  },
  watch: {
    activate(newVal, oldVal) {
      if (newVal) {
        this.$refs.dropLinks.classList.add("on");
        this.$refs.dropLinks.children.forEach((link, i) => {
          setTimeout(() => {
            link.classList.add("on");
          }, i * 100);
        });
      } else {
        this.$refs.dropLinks.classList.remove("on");

        this.$refs.dropLinks.children.forEach((link, i) => {
          setTimeout(() => {
            link.classList.remove("on");
          }, i * 100);
        });
      }
    }
  }
};
</script>

<style lang="stylus" scoped>
.dropDown {
  ul {
    position: absolute;
    padding-top: 40px;
    width: 100%;
    top: 0;
    left: 0;
    pointer-events: none;

    &.on {
      pointer-events: all;
    }

    li {
      margin-top: 5px;
      transform: translate3d(0, 10px, 0);
      opacity: 0;
      transition: all 0.2s;
      cursor: pointer;

      a {
        transition: color 0.2s;
        color: $blue;

        &:hover {
          color: white;
        }
      }

      &.on {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    }
  }
}
</style>